// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// const baseHost='http://localhost:8000';
// const appHost = 'http://localhost:8000';
// const baseHostspurt = "http://localhost:8000";

// const baseHost='margmartuatapi.MargMart.com';   //For Staging.

const baseHost='https://Martapi.MargMart.com'; // For Live.
     
  //https://Martapi.MargMart.com   
export const environment = {
  production: false,
  baseUrl: `${baseHost}/api`
  
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
