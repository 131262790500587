<!-- <div class="page-container">
    <h2>404</h2>
    <h3>Page Not Found</h3>
</div> -->

  <!-- <body class="b-g-light"> -->

  <!-- loader start -->
  <!-- <div class="loader-wrapper">
    <div>
      <img src="assets/images/cosmetic/loader.gif" alt="loader">
    </div>
  </div> -->
  <!-- loader end -->

<section class="error_p">

  <div class="container error-container">
    <div class="row  d-flex align-items-center justify-content-center">
      <div class="col-md-8 text-center">
        <img src="./assets/images/404.png" alt="404" width="20%">
        <h1 class="big-text" style="background: url('./assets/images/404_bg.webp') no-repeat;">404</h1>
        <h2 class="small-text">Oops! - PAGE NOT FOUND</h2>
        <p>The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p>
  
        <!-- <a href="index.html" class="btn btn-solid">GO TO HOME</a> -->
  
      </div>
    
    </div>
  
  
  </div>

</section>