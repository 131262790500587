import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './includes/header/header.component';
import { FooterComponent } from './includes/footer/footer.component';
import { ActivateGuard } from './_helpers/activate.guard';
import { HTTP_INTERCEPTORS,HttpClientModule} from '@angular/common/http'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UserService } from './appServices/user.service';
import { ToastrModule } from 'ngx-toastr';
import {JwtInterceptor} from './_helpers/jwt.interceptor'
import { ConstantsService } from './constants.service';
import { WishlistDetailComponent } from './pages/wish-lists/wishlist-detail/wishlist-detail.component';
import { CartDetailComponent } from './pages/cart/cart-detail/cart-detail.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ApplayoutComponent } from './app-layout/app-layout.component';
import {PageNotfound} from './pages/page-404/page-not-found'
import {DialogModule} from 'primeng/dialog';
import { LoginModule } from './includes/login-modal/login.component';
import { RegisterModelModule } from './includes/register-model/register.component';
import {SidebarModule} from 'primeng/sidebar';
import {OverlayPanelModule} from 'primeng/overlaypanel';
// import { LoginOtpModule } from './pages/login/login-list/login-otp/login-otp.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,WishlistDetailComponent,CartDetailComponent,
    ApplayoutComponent,
    PageNotfound
  ],
  imports: [
    CommonModule,
    BrowserModule,BrowserAnimationsModule,FormsModule, ReactiveFormsModule,SlickCarouselModule,
    AppRoutingModule,HttpClientModule,ToastrModule.forRoot(),
    DialogModule,LoginModule,RegisterModelModule,SidebarModule,OverlayPanelModule,
    // LoginOtpModule
  ],
  providers: [ActivateGuard,UserService,ConstantsService,
  {provide : HTTP_INTERCEPTORS,useClass:JwtInterceptor,multi:true}],
  bootstrap: [AppComponent]
})
export class AppModule { }
