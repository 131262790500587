import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ConstantsService } from '../constants.service';




@Injectable({
    providedIn: 'root'
})

export class GlobalService {
    vendorInfo:any;
    userInfo: any;
    countUpdate$ = new Subject ();
    footerLoginUpdate$ = new Subject ();
    profileLogoUpdate$ = new Subject ();
    loginUserUpdate$ = new Subject ();
    shortUrl:any;
    domainUrl:any;
    constructor(private router: Router, private activatedRoute: ActivatedRoute, public constantsService:ConstantsService) {

    }

    localStorageGetItem() {
    }

    getUserInfo() {
      this.userInfo = JSON.parse(localStorage.getItem(`${this.shortUrl}User`));
      return this.userInfo;
    }

    // shorturl(){
    //   let shortUrl = window.location.pathname.split('/');
    //     shortUrl.shift();
    //   return this.shortUrl = shortUrl[0];
    // }

    public imgUrl(path, name, height, width): string {
        let srcUrl
        // JSON.parse(localStorage.getItem(`${this.shortURl}User`))
        let vCode:string = JSON.parse(localStorage.getItem(`${this.getUserInfo().shortUrl}User`)).vCode;
        return srcUrl = this.constantsService.imageUrl+'/'+vCode+ '?path='+path+ '&name=' +name+ '&width='+width+'&height='+height ;
      }
      public getBaseUrl(): string {
        return environment.baseUrl;
      }
    
}