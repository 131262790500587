<!--section start-->
<section class="login-page section-big-py-space b-g-light" style="padding: 0;">
    <div class="custom-container">
        <div class="row">
            <div class="col-lg-12 ">
                    <!-- <h3 class="text-center">Create account</h3> -->
                    <form class="theme-form" [formGroup]="registerForm" (ngSubmit)="onSubmit()">
                        <div class="row g-3">
                            <div class="col-md-12 form-group">
                                <label for="email">First Name<sup style="color: red;font-size: 15px;">*</sup></label>
                                <input type="text" formControlName="name" autocomplete="off" class="form-control" id="name"
                                    placeholder="Name" [ngClass]="{ 'is-invalid': submitted && f.name.errors }" (keydown.enter)="moveToNextField('phoneNumber')" #firstName>
                                <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                    <div *ngIf="f.name.errors.required">First Name is required.</div>
                                    <div *ngIf="f.name.errors.minlength">First Name should be 3 character.</div>
                                </div>
                            </div>
                            <div class="col-md-12 form-group">
                                <label for="review">Phone Number<sup style="color: red; font-size: 15px;" *ngIf="ismandatory === 1">*</sup></label>
                                <input tabIndex="2" type="number" onkeydown="javascript: return event.keyCode == 69 ? false : true" formControlName="phoneNumber" class="form-control" id="phoneNumber"
                                    placeholder="Phone Number" maxlength="10" (keydown.enter)="moveToNextField('emailId')" #phoneNumberInput
                                    [ngClass]="{ 'is-invalid': submitted && f.phoneNumber.errors }">
                                <div *ngIf="submitted && f.phoneNumber.errors" class="invalid-feedback">
                                    <div *ngIf="f.phoneNumber.errors.required">Phone Number is required.</div>
                                    <div *ngIf="f.phoneNumber.errors.minlength">Phone Number should be 10 characters.</div>
                                </div>
                            </div>

                            <div class="col-md-12 form-group" >
                                <label>Address</label>
                                <input tabIndex="3" type="text" formControlName="address" class="form-control" placeholder="Address (optional)" id="address"
                                (keydown.enter)="moveToNextField('Pincode')" #addressInput>
                            </div>
                            <div class="col-md-12 form-group">
                                <label>Pin Code</label>
                                <input tabIndex="4" type="number" formControlName="pincode" class="form-control" placeholder="Pin code(optional)"
                                (keydown.enter)="moveToNextField('ReferedBy')" #Pincode>
                             </div>   
                            <div class="col-md-12 form-group">
                                <label>Refered By</label>
                                <input tabIndex="5" type="text" formControlName="referedBy" class="form-control" placeholder="Refered By(optional)"
                                (keydown.enter)="moveToNextField('emailId')" #Refby>
                            </div>
                        </div>
                        

                        

                        <div class="row g-3">


                            <div class="col-md-12 form-group">
                                <label>Email<sup style="color: red; font-size: 15px;" *ngIf="ismandatory === 2">*</sup></label>
                                <input tabIndex="6" type="text" formControlName="emailId" class="form-control" placeholder="Enter Your Email Id"
                                    (keydown.enter)="moveToNextField('password')" [ngClass]="{ 'is-invalid': submitted && f.emailId.errors }" #emailIdInput>
                                <div *ngIf="submitted && f.emailId.errors" class="invalid-feedback">
                                    <div *ngIf="f.emailId.errors.required">Email is required.</div>
                                    <div *ngIf="f.emailId.errors.emailId">Please, enter a valid email address.</div>
                                </div>
                            </div>
                            <div class="col-md-12 form-group" style="position: relative;">
                                <label>Password</label>
                                <input [type]="hide ? 'password' : 'text'" formControlName="password" class="form-control"
                                    placeholder="Enter your password" 
                                     (keydown.enter)="moveToNextField('null')" #passwordInput>
                                    <i class="ti-eye" (click)="showPassword()" style="position: absolute;right: 25px;top: 42px;color: black;font-size: 16px; font-weight:bold;"></i>
                                <!-- <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                    [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
                                    <div *ngIf="f.password.errors.required">Password is required.</div>
                                    <div *ngIf="f.password.errors.minlength">Password should be 3 character.</div>
                                </div> -->
                            </div>
                            <button class="btn btn-normal" type="submit" [ngStyle]="{'background-color': getBackgroundColor()}">Create Account</button>
                        </div>
                        <div class="row g-3">
                            <div class="col-md-12">
                                <p>Have you already account?
                                    <a routerLinkActive="active" class="txt-default" (click)="popup()" style="color: blue;">click</a> here to &nbsp;Login</p>
                            </div>
                        </div>
                    </form>
            </div>
        </div>
    </div>
</section>
<!--Section ends-->

<!-- <verify-otp (submitEvent)="verifyOTP($event);"
    PageType="Registration" 
    [CustomerDetails]="customerDetails"
    [CustomerMoblieNumber]="CustomerMoblieNumber"
    [DisplayOTPB]="displayOTPB"
    [OTPformGroup]="OTPForm">
</verify-otp> -->