import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ConstantsService } from '../constants.service';
import { map } from 'rxjs/operators';
import { Router} from '@angular/router';
import { GlobalService } from './globalService';



@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<any>;
    public currentUser: Observable<any>;
    public baseURL = this.constant.basePath;
    domainURL: any = this.globalService.domainUrl;
    profilelogoUpdate$ = new Subject ();

    constructor(private globalService:GlobalService,private http: HttpClient, private constant : ConstantsService,private router: Router) {
        this.currentUserSubject = new BehaviorSubject<any>(this.globalService.vendorInfo??this.globalService.getUserInfo());
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue():any {
        return this.currentUserSubject.value;
    }

    login(params) {
        let vcode =  this.globalService.vendorInfo?this.globalService.vendorInfo.vCode:this.globalService.getUserInfo().vCode;
        return this.http.post<any>(this.baseURL+ `api/vendorCustomer/login-customer/`+vcode,params)
            .pipe(map(user => {
                let info:any = {};
                // login successful if there's a jwt token in the response
                if (user && user.data.token) {
                    info.token = user.data.token;
                    info.userInfo = user.data.user;
                    // localStorage.clear();
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    // localStorage.setItem('currentUser', JSON.stringify(user));
                    this.currentUserSubject.next(info);
                    
                    this.currentUser = this.currentUserSubject.asObservable();
                }

                return user;
            }));
    }
    generateLoginOTP(params){
        let vcode =  this.globalService.vendorInfo?this.globalService.vendorInfo.vCode:this.globalService.getUserInfo().vCode;
        return this.http.post<any>(this.baseURL+ `api/vendorCustomer/otplogin/`+vcode,params)
        // return this.http.post<any>(`http://localhost:8000/`+ `api/vendorCustomer/otplogin/`+vcode,params)
        .pipe(map(user => {
            return user;
        }));
    }
    verifyLoginOTP(params){
        let vcode =  this.globalService.vendorInfo?this.globalService.vendorInfo.vCode:this.globalService.getUserInfo().vCode;
        return this.http.post<any>(this.baseURL+ `api/vendorCustomer/verify-otplogin/`+vcode,params)
        // return this.http.post<any>(`http://localhost:8000/`+ `api/vendorCustomer/verify-otplogin/`+vcode,params)
            .pipe(map(user => {
                let info:any = {};
                // login successful if there's a jwt token in the response
                if (user && user.data.token) {
                    info.token = user.data.token;
                    info.userInfo = user.data.user;
                    // localStorage.clear();
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    // localStorage.setItem('currentUser', JSON.stringify(user));
                    this.currentUserSubject.next(info);
                    
                    this.currentUser = this.currentUserSubject.asObservable();
                }
                return user;
            }));
      }

    verifyOTP(params){
        return this.http.put<any>(this.baseURL + 'api/vendorCustomer/verify-otp',params)
        .pipe(map(user => {
            let info:any = {};
            // login successful if there's a jwt token in the response
            if (user && user.data.token && user.data.enability !== false) {
                info.token = user.data.token;
                info.userInfo = user.data.user;
                // localStorage.clear();
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                // localStorage.setItem('currentUser', JSON.stringify(user));
                this.currentUserSubject.next(info);
                
                this.currentUser = this.currentUserSubject.asObservable();
            }

            return user;
        }));
      }

    logout() {
        // remove user from local storage to log user out
        let info = JSON.parse(localStorage.getItem(`${this.globalService.getUserInfo().shortUrl}User`));
            info.token = null;
            info.userInfo = null;
        localStorage.setItem(`${this.globalService.getUserInfo().shortUrl}User`,JSON.stringify(info));
        this.currentUserSubject.next(info);
        this.router.navigateByUrl(`/home`);
        // this.router.navigateByUrl(`${this.globalService.getUserInfo().shortUrl}/home`);
    }

   
}