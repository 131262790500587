import { Component, EventEmitter, NgModule, OnInit, Output, Input,ViewChild,ElementRef} from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import {OTPmodule} from '../../includes/verify-otp/verify-otp.component'
import { DialogModule } from 'primeng/dialog';
import { GlobalService } from '../../appServices/globalService';
import { ApiintegrationService } from '../../appServices/apiintegration.service';


@Component({
  selector: 'app-register-model',
  templateUrl: './register.component.html'
})
export class RegisterModelComponent implements OnInit {
  registerForm: FormGroup;
  submitted = false;
  ismandatory: any = 1 ;
  displayOTPB:boolean = false;
  customerDetails:any;
  OTPForm:FormGroup;
  isOTPsubmit:boolean;
  display: any =[];
  domainURL: any = this.globalService.domainUrl;
  shortURl:any;
  CustomerMoblieNumber:number;
  hide: boolean = true;
  LoginSuccess
  @Output('displayOTPBox') displayOtp: any = new EventEmitter();
  @Input('visible') public loginPopup;
  @ViewChild('firstName') firstName: ElementRef;
  @ViewChild('phoneNumber') phoneNumber: ElementRef;
  @ViewChild('emailIdInput') emailIdInput: ElementRef;
  @ViewChild('passwordInput') passwordInput: ElementRef;
  templateId: any;
  
  constructor(private appService: ApiintegrationService,
     private globalService:GlobalService,
     private toastr: ToastrService, private router: Router, private formBuilder: FormBuilder) { }
  obj: any;
  ngOnInit(): void {
    this.templateId = this.globalService.userInfo.templateId;
    this.shortURl = this.globalService.shortUrl;
    this.registerForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(3)]],
      phoneNumber: ['', [Validators.required, Validators.minLength(10)]],
      emailId: ['', [ Validators.email]],
      address: [''],
      pincode: [''],
      referedBy: [''],
      password: [''],
      // password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: [''],
    });

    this.OTPForm = this.formBuilder.group({
       val1:['',[Validators.required]],
       val2 :['',[Validators.required]],
       val3:['',[Validators.required]],
       val4:['',[Validators.required]],
       phone:['',[Validators.required]]
    })
  }

  showPassword(){
    this.hide = !this.hide;
  }

  popup(){
    
      this.router.navigateByUrl(`/${this.domainURL}/login`);
      this.loginPopup(false);
      window.scroll(0,0);
    
  }

  getBackgroundColor() {
    switch (this.templateId) {
      case 1:
        return '#0066cb';
      case 2:
        return '#9bc03b';
      case 3:
        return '#FF324D'
      default:
        return '#1f1f1f';
    }
  }

  get f() {
    return this.registerForm.controls;;
  }

  get O() {
    return this.OTPForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    this.ismandatory = this.globalService.userInfo.Address.ismandatory;
    if (this.ismandatory === 1) {
        this.registerForm.get('phoneNumber').setValidators([Validators.required, Validators.minLength(10)]);
        this.registerForm.get('emailId').clearValidators();
    } else if (this.ismandatory === 2) {
        this.registerForm.get('phoneNumber').clearValidators();
        this.registerForm.get('emailId').setValidators([Validators.required, Validators.email]);
    }

    this.registerForm.get('phoneNumber').updateValueAndValidity();
    this.registerForm.get('emailId').updateValueAndValidity();
    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }
    this.obj = this.registerForm.value;
    this.obj.confirmPassword = this.obj.password;
    this.obj.lastName = "";
    this.obj.vendorAdminId = this.globalService.getUserInfo().vCode;
    // this.showloader();
    this.appService.register(this.obj).subscribe((res:any) => {
      if (res) {
        this.customerDetails = res.data;  
        this.CustomerMoblieNumber = res.data.mobileNumber;
        this.OTPForm.controls['phone'].setValue(this.registerForm.controls['phoneNumber'].value);
        this.globalService.profileLogoUpdate$.next(res.data);
        // this.hideloader();
        // this.toastr.success("Registration Successfully");
        this.displayOtp.emit(res.data) 
        //this.timer(3);
        // this.routr.navigate(['/login']);
      }
    }, err => {
      this.toastr.error('Hello world!', 'Toastr fun!');
    })
  }


  timer(minute) {
    // let minute = 1;
    let seconds: number = minute * 60;
    let textSec: any = "0";
    let statSec: number = 60;

    const prefix = minute < 10 ? "0" : "";

    const timer = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      if (statSec < 10) {
        textSec = "0" + statSec;
      } else textSec = statSec;

      this.display = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;
      if (seconds == 0) {
        console.log("finished");
        clearInterval(timer);
        // this.resendOtplLink = true;
      }
     
    }, 1000);
   

  }

//   verifyOTP(event:boolean){
//     alert('popup')
//     this.displayOTPB = false;
//     let details = this.globalService.getUserInfo();
//     setTimeout(() => {
//       //  this.router.navigateByUrl(`/${this.globalService.getUserInfo().shortUrl}/login`)
//       localStorage.setItem(`${details.shortUrl}User`, JSON.stringify(details));
//       this.globalService.vendorInfo.userInfo = details.userInfo;
//       this.globalService.countUpdate$.next(this.customerDetails.isContactVerify==1 || this.customerDetails.isContactVerify===2);
//       this.globalService.countUpdate$.next(true);
//       this.loginSucces.emit(true);
//     //   this.router.navigateByUrl(`/${details.shortUrl}/home`);
//     }, 3000);
//   }



//   hideloader() {
//     document.getElementById('loader').style.display = 'none';
//   }
//   showloader() {
//     document.getElementById('loader').style.display = 'block';
//   }

  handleEvent(event){

  }
  refisterPageRefresh(){
    window.location.reload();
  }

  moveToNextField(fieldName:string): void{
    const control  = this.registerForm.get(fieldName);
     if(fieldName == 'phoneNumber'){
       control?.markAllAsTouched();
      this.phoneNumber.nativeElement.focus();
    }else if (fieldName == 'emailId'){
      control?.markAllAsTouched();
      this.emailIdInput.nativeElement.focus();
    }
    else if (fieldName == 'password'){
      control?.markAllAsTouched();
      this.passwordInput.nativeElement.focus();
    }
  }

}


@NgModule({
    declarations: [RegisterModelComponent],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        RouterModule,
        OTPmodule,
        FormsModule,
        DialogModule,OTPmodule
    ],
    providers: [],
    exports: [RegisterModelComponent]
})

export class RegisterModelModule {

}
