import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient,HttpHeaders, HttpParams } from '@angular/common/http';
import { ConstantsService } from '../constants.service';
import { retry } from 'rxjs/operators';
import { GlobalService } from './globalService';
import { visibility } from 'html2canvas/dist/types/css/property-descriptors/visibility';

const httpOptions = {
  header : new HttpHeaders({
    'Access-control-Allow-Origin' : '*',
    "Authorization" : 'authkey',
    "userid" : '35'
  })
}

@Injectable({
  providedIn: 'root'
})
export class ApiintegrationService {

  constructor(private http: HttpClient, private constant : ConstantsService,
     public globalService:GlobalService,
     private activatedRoute:ActivatedRoute) { }

  public baseURL = this.constant.basePath;

  register(params:any){
    let vcode =  this.globalService.vendorInfo.vCode??this.globalService.getUserInfo().vCode;
    return this.http.post(this.baseURL+'api/vendorCustomer/register/'+vcode, params)
  }

  changePhoneNumber(params:any){
    return this.http.put(this.baseURL+'api/vendorCustomer/change-mobile/'+params.userId, params)
  }

  bannerList(list:any){
    
    return this.http.get(this.baseURL+ `api/margmart/user/bannerlist/${list}`)
  }

  getTestimonial(list){
    return this.http.get(this.baseURL+ `api/margmart/user/get-testimonial/1/${list}`)
  }

  about(){
    return this.http.get(this.baseURL+'api/pages/get_pagedetails/about-us')
  }

  homePage(sorturl:any){
    return this.http.get(this.baseURL + 'api/margmart/user/'+sorturl)
  }

  productList(params){
    let vcode =  this.globalService.vendorInfo.vCode??this.globalService.getUserInfo().vCode;
    // return this.http.get(this.baseURL + `api/product-store/get-product-list/${vcode}?keyword=${params.keyword}&count=${params.count}&limit=${params.limit}&offset=${params.offset}`)
    return this.http.get(this.baseURL + `api/product-store/get-product-list/${vcode}/${params.visiability}?keyword=${params.keyword}&count=${params.count}&limit=${params.limit}&offset=${params.offset}&brandId=${params.brandId}&catId=${params.catId}&salt=${params.salt}`)
  }

  mostViewProduct(){
    return this.http.get(this.baseURL + 'api/margmart/product/most-viewed')
  }

  getPlanDetails(){
    return this.http.get(this.baseURL + 'api/admin/plan/getPlanDetails')
  }

  recentProduct(params:any){
    let vcode =  this.globalService.vendorInfo.vCode??this.globalService.getUserInfo().vCode;
    return this.http.get(this.baseURL + 'api/margmart/user/get-product/'+params.vcode+'/'+params.visiability)
  }

  productCart(){
    return this.http.get(this.baseURL + 'api/margmart/product/cart')
  }

  getProductDetails(params){
    let vcode =  this.globalService.vendorInfo.vCode??this.globalService.getUserInfo().vCode;
    return this.http.get(this.baseURL + 'api/product-store/product-data/'+vcode+'/'+ params.id+'/'+params.visiability)
  }

  resetPassword(params){
    return this.http.put(this.baseURL + 'api/vendorCustomer/add-password/'+params.token,params);
  }

  verifyEmail(params){
    return this.http.put(this.baseURL + 'api/vendorCustomer/verify-email/'+params.token,params);
  }

  // verifyOTP(params){
  //   return this.http.put(this.baseURL + 'api/vendorCustomer/verify-otp',params);
  // }

  moblieSendOTP(params){
    return this.http.put(this.baseURL + 'api/vendorCustomer/send-otp',params);
  } 
  resendOTP(params){
    return this.http.put(this.baseURL + 'api/vendorCustomer/resend-otp',params);
  }

  getAboutus(params:any){
    let vcode =  this.globalService.vendorInfo.vCode??this.globalService.getUserInfo().vCode;
    return this.http.get(this.baseURL + 'api/pages/get-aboutus/'+vcode+'/'+params.position);
  }

  contactUs(params:any){
    let vcode =  this.globalService.vendorInfo.vCode??this.globalService.getUserInfo().vCode;
    return this.http.post(this.baseURL + 'api/vendorCustomer/contact-us/'+vcode, params);
  }

  brandList(param){
    let vcode =  this.globalService.vendorInfo['vCode']??this.globalService.getUserInfo().vCode;
    return this.http.get(this.baseURL + 'api/margmart/product/get-brand-list/'+vcode+'/'+param.limit);
  }

  categoryList(param){
    let vcode =  this.globalService.vendorInfo['vCode']??this.globalService.getUserInfo().vCode;
    return this.http.get(this.baseURL + 'api/margmart/product/get-category-list/'+vcode+'/'+param.limit);
  }
//For Get Latest Deals
  getLetestDeals(params){
    let vcode =  this.globalService.vendorInfo.vCode??this.globalService.getUserInfo().vCode;
    return this.http.get(this.baseURL + `api/product-store/get-product-deal-list/${vcode}/${params.visiability}?keyword=${params.keyword}&count=${params.count}&limit=${params.limit}&offset=${params.offset}&brandId=${params.brandId}&catId=${params.catId}`)
  }

  AddToCartProduct(params){
    let vcode =  this.globalService.vendorInfo.vCode??this.globalService.getUserInfo().vCode;
    return this.http.post(this.baseURL + 'api/customer-cart/add-cart/'+vcode,params);
  }

  getCustomerCart(param:any){
    let vcode =  this.globalService.vendorInfo.vCode??this.globalService.getUserInfo().vCode;
    let customerId = this.globalService.getUserInfo().userInfo.id;
    return this.http.get(this.baseURL + 'api/customer-cart/customer-cart-list/'+vcode+'/'+customerId+'/'+param.itemId+'?count=0&limit=0&offset=0&saveLater='+param.saveLater);
  }

  deleteCartItem(params){
    let vcode =  this.globalService.vendorInfo.vCode??this.globalService.getUserInfo().vCode;
    return this.http.post(this.baseURL + 'api/customer-cart/delete-cart-item',params);
  }

  updateCartProduct(params){
    let vcode =  this.globalService.vendorInfo.vCode??this.globalService.getUserInfo().vCode;
    return this.http.put(this.baseURL + 'api/customer-cart/update-cart/'+vcode,params);
  }

  saveForLater(params){
    return this.http.put(this.baseURL + 'api/customer-cart/update-saveForLater/'+params.id+'/'+params.value,{});
  }

  placeOrder(params){
    let vcode =  this.globalService.vendorInfo.vCode??this.globalService.getUserInfo().vCode;
    return this.http.post(this.baseURL + 'api/orders/place-order/'+vcode,params);
  }

  adddTowishList(params){
    let vcode =  this.globalService.vendorInfo.vCode??this.globalService.getUserInfo().vCode;
    return this.http.post(this.baseURL + 'api/customer-wishlist/add-product-to-wishlist/'+vcode,params);
  }

  getwishList(params){
    let vcode =  this.globalService.vendorInfo.vCode??this.globalService.getUserInfo().vCode;
    return this.http.get(this.baseURL + 'api/customer-wishlist/get-wishlist-product/'+vcode+'?count='+params.count+'&limit='+params.limit+'&offset='+params.offset);
  }

  removeProductFromWihList(params){
    let vcode =  this.globalService.vendorInfo.vCode??this.globalService.getUserInfo().vCode;
    return this.http.delete(this.baseURL + 'api/customer-wishlist/wishlist-product-delete/'+params.id);
  }

  addQuery(params){
    let vcode =  this.globalService.vendorInfo.vCode??this.globalService.getUserInfo().vCode;
    return this.http.post(this.baseURL + 'api/customerQuery/add-query/'+vcode,params);
  }


  getQueryList(params){
    let vcode =  this.globalService.vendorInfo.vCode??this.globalService.getUserInfo().vCode;
    return this.http.get(this.baseURL + 'api/customerQuery/get-customer-query-list/'+vcode+'?count='+params.count+'&limit='+params.limit+'&offset='+params.offset);
  }

  createTestimonial(params){
    let url = 'api/margmart/user/add-testimonial';
    return this.http.post(this.baseURL + 'api/margmart/user/add-testimonial',params);     
  }


  saveOrderTransections(params){
    let vcode =  this.globalService.vendorInfo.vCode??this.globalService.getUserInfo().vCode;
    return this.http.post(this.baseURL + 'api/orders/order-transaction/'+vcode,params);
  }

  validateProductQuantity(params){
    let vcode =  this.globalService.getUserInfo().vCode;
    return this.http.post(this.baseURL + 'api/orders/check-product-availablity',params);
  }

  getOrderHistory(){
    let vcode =  this.globalService.vendorInfo.vCode??this.globalService.getUserInfo().vCode;
    return this.http.get(this.baseURL + 'api/orders/customer-order-history/'+vcode);
  }

  getNewOrderHistory(params){
    let vcode =  this.globalService.vendorInfo.vCode??this.globalService.getUserInfo().vCode;
    return this.http.get(this.baseURL + 'api/orders/customer-order-history/'+vcode+'?count='+params.count+'&limit='+params.limit+'&offset='+params.offset);
  }

  getCoustomerDetail(params){
    return this.http.get(this.baseURL + 'api/orders/customer-order-detail-byId/'+params.Id);
  }

  forgotPassword(params){
    let vcode =  this.globalService.vendorInfo.vCode??this.globalService.getUserInfo().vCode;
    return this.http.post(this.baseURL + 'api/vendorCustomer/forgot-password/'+vcode,params);
  }

  getCartItemAndQueryCount(){
    let vcode =  this.globalService.vendorInfo.vCode??this.globalService.getUserInfo().vCode;
    return this.http.get(this.baseURL + 'api/customer-wishlist/get-count-wishlist-query-cart/'+vcode);
  }

  cancelMyOrder(params){
    return this.http.get(this.baseURL + 'api/order/order-accept-reject-by-vendor/', { params: params });
    // return this.http.get(`http://localhost:8000/` + 'api/order/order-accept-reject-by-vendor/', { params: params });
  }

  globalProductSearch(params){
    let vcode =  this.globalService.vendorInfo.vCode??this.globalService.getUserInfo().vCode;
    return this.http.get(this.baseURL + `api/margmart/user/global-product-serch/${vcode}?keyword=${params.keyword}&limit=${params.limit}&offset=${params.offset}`);
  }
  
  public getorderPdfData(id) {
    return this.http.get(this.baseURL + 'api/orders/customer-order-pdf-data?orderId='+id);
  }

  public getInvoicePdfData(id) {
    return this.http.get(this.baseURL + 'api/orders/customer-invoice-pdf-data?orderId='+id);
  }
  
  // public getorderPdfDatas(id) {
  //   return this.http.get(this.baseURL + 'api/orders/customer-order-pdf-data?orderId='+id);
  // }

  getCustomerProfile(params:any){
    let vcode =  this.globalService.vendorInfo?.vCode??this.globalService.getUserInfo().vCode;
    return this.http.get(this.baseURL+'api/vendorCustomer/get-profile', params)
  }

  
 updateShippingStatus(params){
    return this.http.post(this.baseURL+'api/margmart/product-utility/update-erp-shipping-status',params);
 }

 getOrderTrackStatus(orderId){
  return this.http.get(this.baseURL + 'api/orders/customer-order-shipping-history?orderId='+orderId);
 }
  
 postContactDetails(params:any){
   return this.http.post(this.baseURL+'api/admin/contact/add-query',params);
 }

 //Get List of change order.
 getChangeOrderList(params){
  let vcode =  this.globalService.vendorInfo.vCode??this.globalService.getUserInfo().vCode;
  return this.http.get(this.baseURL + 'api/order/get-temprary-order-data/'+vcode+'?orderId='+params.orderId);
}
 //Get Update the of change order.
 getChangeAcceptOrder(params){
  let vcode =  this.globalService.vendorInfo.vCode??this.globalService.getUserInfo().vCode;
  return this.http.get(this.baseURL + 'api/orders/approve-temprary-order-data/'+vcode+'?orderId='+params.orderId);
}
 //Get Reject the of change order.
 getChangeRejectOrder(params){
  let vcode =  this.globalService.vendorInfo.vCode??this.globalService.getUserInfo().vCode;
  return this.http.get(this.baseURL + 'api/orders/reject-temprary-order-data/'+vcode+'?orderId='+params.orderId);
}
 //Get Quantity of Product Stock is there is Not.
 getProductQuantity(params){
  let vcode =  this.globalService.vendorInfo.vCode??this.globalService.getUserInfo().vCode;
  return this.http.post(this.baseURL + 'api/orders/check-product-quantity/'+vcode,params);
}
 //Get Key word Of Globel Search.
 getglobelSearch(params){
  let vcode =  this.globalService.vendorInfo.vCode??this.globalService.getUserInfo().vCode;
  return this.http.get(this.baseURL + 'api/margmart/user/global-product-serch-list/'+vcode+'/'+params.keyword);
}
  
}
