import { Component, Input, OnInit } from '@angular/core';
import { CartService } from 'src/app/appServices/cart.service';
import { ToastrService } from 'ngx-toastr';
import * as $ from 'jquery';
import { ApiintegrationService } from '../../../appServices/apiintegration.service'; 
import {GlobalService} from '../../../appServices/globalService';
import { ConstantsService } from '../../../constants.service';

@Component({
  selector: 'app-cart-detail',
  templateUrl: './cart-detail.component.html',
  styleUrls: ['./cart-detail.component.css']
})
export class CartDetailComponent implements OnInit {


  @Input('CartItemCount') getCartitemCount;
  constructor(private cartService: CartService,
     private globalService:GlobalService,
     public baseUrl:ConstantsService,
     private toastr: ToastrService,private apiService : ApiintegrationService) {
  //    this.apiService.productCart().subscribe(res =>{
  //    console.log("cart product", res)
  //  })
   }
  public cartProduct : any =[];
  cartProductCheck : any =[];
  public grandTotal:number =0;
  cartProductCheckLen:number = 1;
  eventPackagePrice = [];
  //Price : number = 0;
  totalProductAmount:number = 0;
 userInfo:any;



  ngOnInit(): void {
    setTimeout(() => {
      if(this.globalService.getUserInfo().token){
        this.userInfo = this.globalService.getUserInfo()
        // this.getCartItem();
      }
      
    }, 1000);
  }


  getCartItem(){
    let param = {
      saveLater:0,
      itemId:0
    }
    this.apiService.getCustomerCart(param).subscribe((res:any)=>{
      this.cartProduct = res.data.finalResult;
      this.getCartitemCount(this.cartProduct.length);
      this.totalProductAmount = this.cartProduct.reduce((a,b)=>{
        return a+Number(b.total);
      },0)
      console.log( this.totalProductAmount,"total price----")
    })
  }



  increase_quantity(temp_package){
    this.cartProductCheck.push(temp_package);
    this.cartProductCheckLen = this.cartProductCheck.length
    if(this.cartProductCheck.length > 4){
      this.cartProductCheck.pop(temp_package+1)
      $(".qty-plus").attr("disabled")
      return this.toastr.warning("cart item not max than 4")
    }else{
      temp_package.quantity++
      this.grandTotal +=  parseInt(temp_package.price)
    }
  }

  decrease_quantity(temp_package){
      this.cartProductCheckLen = this.cartProductCheckLen-1;
      if(temp_package.quantity == 0){
        return this.cartService.removeCartItem(temp_package)
      }
      temp_package.quantity--
      this.grandTotal -=  parseInt(temp_package.price)
  }

  countPrice(cartItem){
    this.grandTotal = 0;
     for(let p of cartItem){
       this.grandTotal +=  parseInt(p.price)*p.quantity
     }
 }

}
