import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient,HttpHeaders, HttpParams } from '@angular/common/http';
import { ConstantsService } from '../../constants.service';
import { GlobalService } from '../../appServices/globalService';



@Injectable({
  providedIn: 'root'
})

export class ProfileService {
  
  constructor(private http: HttpClient, private constant : ConstantsService,
    public globalService:GlobalService,
    private activatedRoute:ActivatedRoute) { }

  public baseURL = this.constant.basePath;

  getCustomerProfile(params:any){
    let vcode =  this.globalService.vendorInfo.vCode??this.globalService.getUserInfo().vCode;
    return this.http.get(this.baseURL+'api/vendorCustomer/get-profile', params)
  }

  updateProfile(params){
    let vcode =  this.globalService.vendorInfo.vCode??this.globalService.getUserInfo().vCode;
    return this.http.post(this.baseURL+'api/vendorCustomer/edit-profile/'+vcode, params)
  }
  
  moblieSendOTP(params){
    return this.http.put(this.baseURL + 'api/vendorCustomer/send-otp',params);
  }

  uploadProfileImage(params){
    let vcode =  this.globalService.vendorInfo.vCode??this.globalService.getUserInfo().vCode;
    return this.http.post(this.baseURL+'api/vendorCustomer/uplode-image', params)
  }

  changePassword(params:any){
    let vcode =  this.globalService.vendorInfo.vCode??this.globalService.getUserInfo().vCode;
    return this.http.post(this.baseURL+'api/vendorCustomer/change-password', params)
  }

  addCustomerAddress(params:any){
    let vcode =  this.globalService.vendorInfo.vCode??this.globalService.getUserInfo().vCode;
    return this.http.post(this.baseURL+'api/CustomerAddress/add-address', params)
  }

  getCustomerAddress(params:any){
    let vcode =  this.globalService.vendorInfo.vCode??this.globalService.getUserInfo().vCode;
    return this.http.get(this.baseURL+'api/CustomerAddress/get-address-list?count=0&limit=10&offset=0')
  }

  deleteCustomerAddress(params:any){
    let vcode =  this.globalService.getUserInfo().vCode;
    return this.http.delete(this.baseURL+'api/CustomerAddress/delete-address/'+params.id)
  }

  updateCustomerAddress(params:any){
    let vcode =  this.globalService.vendorInfo.vCode??this.globalService.getUserInfo().vCode;
    return this.http.put(this.baseURL+'api/CustomerAddress/update-address/'+params.id, params);
  }

  addDefaultAddress(params:any){
    let vcode =  this.globalService.vendorInfo.vCode??this.globalService.getUserInfo().vCode;
    return this.http.put(this.baseURL+'api/CustomerAddress/default-address/'+params.id, params);
  }

  addCompanyDetails(params:any){
    let vcode =  this.globalService.getUserInfo().vCode;
    return this.http.post(this.baseURL+'api/company/add-company/'+vcode, params)
  }

  getCompanyDetails(params:any){
    let vcode =  this.globalService.vendorInfo.vCode??this.globalService.getUserInfo().vCode;
    return this.http.get(this.baseURL+'api/company/company-detail/'+vcode)
  }


}
