import { Component, OnInit, Input, ChangeDetectorRef, Renderer2,HostListener } from '@angular/core';
import { CartService } from 'src/app/appServices/cart.service';
import { Subscription } from 'rxjs';
import { AuthenticationService } from '../../appServices/authentication.service';

import { ActivatedRoute, Router } from '@angular/router';
import { GlobalService } from '../../appServices/globalService';
import { ApiintegrationService } from '../../appServices/apiintegration.service';
import {ConstantsService} from '../../constants.service'
import { count } from 'rxjs/operators';
import { ProfileService } from 'src/app/pages/edit-profile/profile.service';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnInit {
  checkTrue: boolean = true;
  checkFalse: boolean = false;
  isSidebarClosed = false;
  sidebarActive: boolean = false;
  // isNavbarFixed: boolean = false;
  userProfileUpdate:any;
  public totalItem: number = 0;
  public searchTerm: string = "";
  currentUser: any;
  loginUser:any;
  currentUserSubscription: Subscription;
  users: any = [];
  shortURl: any = '';
  categoryList=[];
  itemCount:number = 0;
  footerData:any;
  cartItemCount:any;
  imageUrls:string;
  newCompany:any;
  companyLogo:any;
  headAllLogo:any
  companyPath:string;
  companyImage:string;
  dropdownVisible: boolean = false;
  public vCode:any;
  urlShort:any;
  isLogin: boolean = false;
  displayOpenScreen:string ='';
  visibleSidebar1:boolean = false;
  searchForm: FormGroup;
  searchResult: any;
  search_show:boolean = false;
  search_show1:boolean = false;
  domainURL: any = this.globalService.domainUrl;
  @Input('TemplateId') templateId;
  constructor(private cartService: CartService,
    public globalService:GlobalService,
    private apiService:ApiintegrationService,
    public constantsService:ConstantsService,
     private authenticationService: AuthenticationService, private router: Router,
     private profileService:ProfileService, private fb: FormBuilder,private renderer: Renderer2,private changeDetection: ChangeDetectorRef) {
     this.shortURl = this.globalService.shortUrl;
    setTimeout(() => {
      this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
        if (user['userInfo']) {
          this.currentUser = user['userInfo'];
          this.checkFalse = true
          this.checkTrue = false;
          // this.getProfileData();
        } else {
          this.checkTrue = true;
          this.checkFalse = false
        }

      });

    }, 3000);
     setTimeout(() => {
      this.globalService.loginUserUpdate$.subscribe ( count =>{
        this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
          if (user['userInfo']) {
            this.currentUser = user['userInfo'];
            this.checkFalse = true
            this.checkTrue = false;
            if(this.currentUser?.secrateCode){
              this.getProfileData();
            }
           // this.getProfileData();
          } else {
            this.checkTrue = true;
            this.checkFalse = false
          }
         
        });
  
      });
      },3000);
    this.imageUrls = this.constantsService.imageUrl;
    this.renderer.listen('window', 'click',(event:Event)=>{
      this.search_show = false;
      this.search_show1 = false;
    });
  }


  logout() {
    this.authenticationService.logout();
    this.changeDetection.detectChanges();
    // window.location.reload();
    this.router.navigateByUrl(`/${this.domainURL}/home`);
    
  }

  ngOnInit(): void {
    this.searchForm = this.fb.group({
      searchName: ['']
    }); 
    this.getCartItemAndQueryCount();
    this.shortURl = this.globalService.shortUrl;
    this.getFooterData();
    this.getProfileData();
    setTimeout(() =>{
      $(".dark-menu-item").on('click', function (){
        $('.sm-horizontal').css("right","-410px");
      });
    },1000);

     this.globalService.countUpdate$.subscribe ( count =>{
       this.getCartItemAndQueryCount();
     });

     this.globalService.profileLogoUpdate$.subscribe ( count =>{
     if(this.currentUser?.secrateCode){
      this.getProfileData();
     }
     else{
     }

    });
    return this.companyLogo;
  }

  sidenav(){
    $('.toggle-nav').on('click', function () {
      $('.sm-horizontal').css("right","0px");
    });
    $(".mobile-back").on('click', function (){
      $('.sm-horizontal').css("right","-410px");
    });
  }
  

  hideClass(){
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    setTimeout(()=>{
      if(this.checkFalse){
        $(".dark-menu-item").on('click', function (){
          $('.sm-horizontal').css("right","-410px");
        });
      }
      else if(this.checkTrue){
        $(".dark-menu-item").on('click', function (){
          $('.sm-horizontal').css("right","-410px");
        });
      }
    },5000)
    this.searchTerm = '';
    this.searchForm.reset();
  }
  close(){
    this.visibleSidebar1 = false;
  }
  
// For Search Product.
  searchProducts(event: any) {
    if (event.key === "Enter") {
      event.preventDefault();
      this.searchTerm = (event.target as HTMLInputElement).value;
      this.searchProduct();
    }
  }
  searchProductsByone(){
    event.preventDefault();
    this.searchTerm = this.searchForm.value.searchName;
    this.searchProduct();
  }

  toggleDropdown() {
    this.dropdownVisible = !this.dropdownVisible;
  }

  searchProduct() {
     this.router.navigateByUrl(`/${this.domainURL}/product?search=${this.searchTerm}`);
  }

  openLoginpage(){
    this.router.navigateByUrl(`/${this.domainURL}/login`);
    this.searchTerm = '';
  }
  
  openRegisterPage(){
    this.router.navigateByUrl(`/${this.domainURL}/register`);
    this.searchTerm = '';
  }

  getFooterData(){
    this.shortURl = this.globalService.shortUrl;
    this.apiService.homePage(this.shortURl).subscribe((res:any)=>{
       this.footerData = res.data;
       this.newCompany = this.footerData.logo.split('//');
       this.companyPath = this.newCompany[0];
       this.companyImage = this.newCompany[1] ;
      //  this.imageUrls  +'/'+this.vCode+ '?path='+`${ this.newCompany[0]}`+ '/' +'&name=' +`${this.newCompany[1]}`+ '&width=160&&height=150' 
      //  this.imageUrls+'/'+this.vCode+ '?width=160&height=150&path=' + `${ this.newCompany[0]}`+ '/' + '&name=' + `${this.newCompany[1]}`;
       this.companyLogo = this.imageUrls+'/'+this.footerData.vCode+ '?width=100&height=100&path=' + `${ this.newCompany[0]}`+ '/' + '&name=' + `${this.newCompany[1]}`;
       this.headAllLogo = `https://margmartstorage.blob.core.windows.net/vendor-${this.footerData.vendorId}/${this.newCompany[0]}/${this.newCompany[1]}`;
       if(this.checkFalse){
        this.getCartItemAndQueryCount();
      }
      else{
        this.getCategoryList();
       
      }
    });
  }

  getCartItemAndQueryCount(){
     this.apiService.getCartItemAndQueryCount().subscribe({
       next: (result:any)=>{
           this.cartItemCount = result.data;
           this.getCategoryList();
           this.changeDetection.detectChanges();
       },
       error: (error)=>{
       }
     })
  }

  getProfileData(){
    let data = {};
    this.apiService.getCustomerProfile(data).subscribe((res:any)=>{
     this.loginUser = res.data;
    },
    error=>{
    })

  }

  

  search(event: any) {
    this.searchTerm = (event.target as HTMLInputElement).value;
  }

  getCategoryList() {
    let params = {limit:0}
    this.apiService.categoryList(params).subscribe((res:any)=>{
      this.categoryList = res.data;
    },
    error=>{console.log(error)})
  }
  

  // getGlobelSearch(event:any){
  //   let params: any = {};
  //   params.keyword = event.target.value
  //   this.apiService.getglobelSearch(params).subscribe({
  //     next: (result:any)=>{
  //       console.log(result)
  //       this.searchResult = result.data;
  //     },
  //     error: (error)=>{

  //     }
  //   })
  // }
  getGlobelSearch(event:any){
    let params: any = {};
    params.keyword = event.target.value;
    this.search_show = false;
    this.search_show1 = false;
    this.apiService.getglobelSearch(params).subscribe({
      next: (result:any)=>{
         if(params.keyword.length>1)
         {
           if(result.data.length!=0)
           {
            this.search_show = true;
            this.search_show1 = false;
            this.searchResult = result.data;
           }
           else{
            this.search_show = false;
            this.search_show1 = true;
           }
         }        
         else{
           if(params.keyword.length==1)
           {
            this.search_show = false;
            this.search_show1 = false;
            this.searchResult = [];
           }else{
            this.search_show = false;
            this.search_show1 = false;
            this.searchResult = [];
           }           
         }
      },
      error: (error)=>{
      }
    })
  }

  closes() {
    this.sidebarActive = false;
  }
  scrollToTop() {
    document.body.scrollTop = 0;
  }


  toggleSidebar() {
    this.sidebarActive = !this.sidebarActive;
  }
  
  // @HostListener('window:scroll', ['$event']) onScroll() {
  //   if (window.scrollY > 500) {
  //     this.isNavbarFixed = true;
  //   } else {
  //     this.isNavbarFixed = false;
  //   }
  // }
  onRowSelect(event) {
    this.router.navigateByUrl(`/${this.domainURL}/product?search=${event.name}`);
}



  public CartItemCount = (count)=>{
       this.itemCount = count;
  }

  loginSuccess(event) {
    this.isLogin = false;
    if (this.displayOpenScreen == "Edit Profile"){
    this.getEditProfleData();
    this.searchTerm = '';
    }
    else if (this.displayOpenScreen == "Order History"){
     this.getOrderHistoryData();
     this.searchTerm = '';
    }
    else if (this.displayOpenScreen == "AddToCart"){
      this.getCartData();
      this.searchTerm = '';
     }
     else if (this.displayOpenScreen == "Wishlist"){
      this.getWishListData();
      this.searchTerm = '';
     }
     else if (this.displayOpenScreen == "Query List"){
      this.getQueryData();
      this.searchTerm = '';
     }
    else{
      this.isLogin = false;
    }

  } 


  getEditProfleData(){
    if(this.globalService.getUserInfo().token){
      this.router.navigateByUrl(`/${this.domainURL}/queryList`);
      window.scroll(0, 0);
    }
    else{
      this.displayOpenScreen = "Edit Profile";
      this.isLogin = true;
    }
  }

  getOrderHistoryData(){
    if(this.globalService.getUserInfo().token){
      this.router.navigateByUrl(`/${this.domainURL}/orderHistory`);
      window.scroll(0, 0);
    }
    else{
      this.displayOpenScreen = "Order History";
      console.log(this.displayOpenScreen,"=============== not login");
      this.isLogin = true;
    } 
  } 
   
  getWishListData(){
    if(this.globalService.getUserInfo().token){
      this.router.navigateByUrl(`/${this.domainURL}/wishLists`);
      window.scroll(0, 0);
    }
    else{
      this.displayOpenScreen = "Wishlist";
      console.log(this.displayOpenScreen,"=============== not login");
      this.isLogin = true;
    } 
  } 
  getCartData(){
    if(this.globalService.getUserInfo().token){
      this.router.navigateByUrl(`/${this.domainURL}/cart`);
      window.scroll(0, 0);
    }
    else{
      this.displayOpenScreen = "Query List";
      console.log(this.displayOpenScreen,"=============== not login");
      this.isLogin = true;
    } 
  } 
  
  getQueryData(){
    if(this.globalService.getUserInfo().token){
      this.router.navigateByUrl(`/${this.domainURL}/queryList`);
      window.scroll(0, 0);
    }
    else{
      this.displayOpenScreen = "AddToCart";
      console.log(this.displayOpenScreen,"=============== not login");
      this.isLogin = true;
    } 
  } 

  navItem(){
    $('.megamenu-off-canvas').addClass('.show');
  }
  
}
