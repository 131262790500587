import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConstantsService {
  // Localhost setting
  // public host = 'localhost';
  // public port = '8000';
  // public basePath = `http://${this.host}:${this.port}/`;
  // public imageUrl = `http://${this.host}:${this.port}/api/media/image-resize`;
  // public paymentMessageUrl = 'http://localhost:4200/';

  //// Server setting
 
  public host = 'Martapi.MargMart.com';   //Live Url.
  public paymentMessageUrl = 'https://Martapi.MargMart.com/';

  // public host = 'margmartuatapi.MargMart.com';  //For Staging.
  // public paymentMessageUrl =  'https://margmartuatapi.MargMart.com/';
  
  public basePath = `https://${this.host}/`;
  public imageUrl = `https://${this.host}/api/media/image-resize`;


//https://margmartuatapilinux.azurewebsites.net/      //For Staging.
// https://Martapi.MargMart.com/      //Live Url.
  public base_url = window.location.origin;
  public host_url = window.location.host;
  public pathArray = window.location.pathname.split( '/' );

  
}
