import {Component, OnInit} from '@angular/core';


@Component({
     templateUrl:'./page-not-found.html',
     styleUrls: ['./page-not-found.scss']
})

export class PageNotfound implements OnInit {

    ngOnInit(): void {
        
    }
}