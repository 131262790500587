import { Injectable } from '@angular/core';
import { Resolve, Router, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { Observable,of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { GlobalService } from './globalService';
import { HttpClient, HttpHeaders, HttpParams,HttpErrorResponse } from '@angular/common/http';
import { ConstantsService } from '../constants.service';
import { throwError } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class BookResolverService implements Resolve<any> {
    currentUserSubscription: any;
    constructor(private globelService: GlobalService,
        private router: Router, private activatedRoute: ActivatedRoute,
        private http: HttpClient, private constant: ConstantsService) {
    }
    public baseURL = this.constant.basePath;
    public url;
    public respo;

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        if (!(window.location.hostname === 'margmart.com' || window.location.hostname === 'www.margmart.com')) {
        // if (window.location.hostname !== 'margmartuatui.azurewebsites.net') {
            const param = { domain: window.location.hostname };
            return this.http.post(this.baseURL + 'api/margmart/user/get-user-domain', param).pipe(
                switchMap((result: any) => {
                    this.globelService.shortUrl = result.data.shortUrl;
                    this.globelService.domainUrl = ``;
                    return this.http.get(this.baseURL + 'api/margmart/user/' + result.data.shortUrl).pipe(
                        map((ShortURl) => ({ ShortURl: ShortURl })),
                        catchError((error) => {
                            const message = `Retrieval error: ${JSON.stringify(error)}`;
                            return of({ ShortURl: null, error: message });
                        })
                    );
                })
            );
        } else {
            let shortURl: any = route.paramMap.get('sorturl');
            this.globelService.shortUrl = shortURl;
            this.globelService.domainUrl = `/${shortURl}`;
                return this.http.get(this.baseURL + 'api/margmart/user/' + shortURl).pipe(
                    map((ShortURl) => ({ ShortURl: ShortURl })),
                    catchError((error) => {
                        const message = `Retrieval error: ${JSON.stringify(error)}`;
                        return of({ ShortURl: null, error: message });
                    })
                );
        }
    }
}