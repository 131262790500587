import {Component, Input, ViewEncapsulation} from '@angular/core';
import { ActivatedRoute, Router} from '@angular/router';
import { ApiintegrationService } from '../appServices/apiintegration.service';
import { GlobalService } from '../appServices/globalService';
import { BookResolverService } from '../appServices/resolve.service';
import { Title } from '@angular/platform-browser';

@Component({
    selector:'app-lauout',
   templateUrl:'./app-layout.component.html',
   styleUrls: ['./app-layout.component.scss'],
   encapsulation: ViewEncapsulation.None
})

export class ApplayoutComponent {
    shortURl:any;
    templateId:any;
    errorMessage: any;
    ngOnInit(): void {
        this.templateId = this.globalService.userInfo.templateId;
    }
    constructor(private activatedRoute:ActivatedRoute,
        public globalService:GlobalService,
        public title: Title,
        private apiService:ApiintegrationService){
            this.shortURl = this.globalService.shortUrl;
        // this.shortURl = this.activatedRoute.snapshot.params['sorturl'];
        // this.errorMessage = resolvedData.ShortURl;
        this.activatedRoute.data.subscribe(res=>{
            this.errorMessage = JSON.stringify(res.data.error);
            this.templateId = res.data?.ShortURl.data.templateId;
            let data :any= {
                vCode: res.data.ShortURl.data.vCode,
                shortUrl: this.shortURl,
                bussinessSubTypeId:res.data.ShortURl.data.bussinessSubTypeId,
                userInfo:null,
                token:null,
                Address:res.data.ShortURl.data,
                isMargPaymentStatus:res.data.ShortURl.data.isMargPaymentStatus,
                templateId: res.data.ShortURl.data.templateId
            }
            // for (let i = 0; i < localStorage.length; i++) {
            //   if (localStorage.key(i).endsWith('User')) {
            //     localStorage.removeItem(localStorage.key(i));
            //   }
            // }
            // this.globalService.vendorAddress.next()
            if(localStorage.getItem(`${this.shortURl}User`)){
                let info = JSON.parse(localStorage.getItem(`${this.shortURl}User`))
                if (info.userInfo) {
                    data.userInfo = info.userInfo;
                    data.token = info.token;
                    data.Address = info.Address;
                    data.isMargPaymentStatus = info.isMargPaymentStatus;
                    data.templateId = info.templateId;
                    localStorage.setItem(`${this.shortURl}User`,JSON.stringify(data));
                } 

            }
            this.globalService.vendorInfo = data;
            this.title.setTitle(data.Address.companyName);
            localStorage.setItem(`${this.shortURl}User`,JSON.stringify(data));
            // setTimeout(() => {
               this.globalService.localStorageGetItem(); 
            // },);
        },
        (error)=>{
        });
    }
    getBackgroundColor() {
        switch (this.templateId) {
          case 1:
            return '#ffffff';
          case 2:
            return '#ffffff';
          case 3:
            return '#ffffff';
          default:
            return '#000000';
        }
      }
    
}