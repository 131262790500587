import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'pharmacy-distributor';
  shortURl:any='';
  constructor(private activatedRoute:ActivatedRoute){
    // Prevents window from moving on touch on newer browsers.
    window.addEventListener('scroll', function (event) {
      event.preventDefault()
    }, { passive: false })

  }
  onActivate(event){
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }

  
}
