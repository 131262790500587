
import { Component, EventEmitter, Input, NgModule, OnInit, Output } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ApiintegrationService } from '../../appServices/apiintegration.service';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { GlobalService } from '../../appServices/globalService'
import { AuthenticationService } from 'src/app/appServices/authentication.service';


@Component({
  selector: 'verify-otp',
  templateUrl: './verify-otp.component.html',
  styleUrls: ['./verify-otp.component.css']
})

export class OTPverificationComponent implements OnInit {
  domainURL: any = this.globalService.domainUrl;
  isOTPsubmit: boolean;
  display: any = [];
  templateId:any;
  resendOtplLink: boolean = false;
  @Output('submitEvent') submitOTP: any = new EventEmitter();

  @Input('CustomerDetails') customerDetails: any;
  @Input('CustomerMoblieNumber') CustomerMoblieNumber: number;
  @Input('OTPformGroup') OTPForm: FormGroup;
  @Input('DisplayOTPB') displayOTPB: boolean;
  @Input('PageType') pageType: any;
  edit = true;
  // @Input('pageName') pageType:any;
  varifyApproved: boolean = false;


  constructor(private formBuilder: FormBuilder, private appService: ApiintegrationService,private appServices: AuthenticationService,
    private router: Router,
    private globalService: GlobalService,
    private toastrService: ToastrService) {
    // this.OTPForm = this.formBuilder.group({
    //     val1:['',[Validators.required]],
    //     val2 :['',[Validators.required]],
    //     val3:['',[Validators.required]],
    //     val4:['',[Validators.required]]
    //  })
    // this.timer(3);
  }

  ngOnInit(): void {
  }



  get O() {
    return this.OTPForm.controls;
  }

  ngOnChanges() {
    if (this.customerDetails) {
      this.OTPForm.controls['phone'].setValue(this.CustomerMoblieNumber || this.customerDetails.mobileNumber);
      this.timer(3);
    }
  }

  numericOnly(event: any): boolean {
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }

  getBackgroundColor() {
    switch (this.templateId) {
      case 1:
        return '#0066cb';
      case 2:
        return '#9bc03b';
      case 2:
        return '#FF324D';  
      default:
        return '#1F1F1F';
    }
  }

  verifyOTP() {
    if (this.CustomerMoblieNumber) {
      this.OTPForm.controls['phone'].setValue(this.CustomerMoblieNumber);
    } else {
      this.OTPForm.controls['phone'].setValue(this.customerDetails.mobileNumber);
    }
    if (this.OTPForm.valid) {
      let form = this.OTPForm.value;
      let params: any = {};
      params.customerId = this.customerDetails.id;
      params.otp = form.val1 + form.val2 + form.val3 + form.val4;
      params.mobile = this.OTPForm.controls['phone'].value;
      this.appServices.verifyOTP(params).subscribe((res: any) => {
        this.toastrService.success('Your OTP verified successfully');
        if(res.data.enability == false){
          this.varifyApproved = true;
        }else{
          setTimeout(() => {
            
            let details = this.globalService.getUserInfo();
            details.userInfo = res.data.user;
            details.token = res.data.token;
            localStorage.setItem(`${details.shortUrl}User`, JSON.stringify(details));
            this.globalService.vendorInfo.userInfo = details.userInfo;
            this.globalService.countUpdate$.next(true);
            this.globalService.loginUserUpdate$.next(this.customerDetails.isContactVerify == 1 || this.customerDetails.isContactVerify === 2);
            this.submitOTP.emit(true)
            if (this.pageType == 'Registration' || this.pageType == 'Login') {
              this.router.navigateByUrl(`/${this.domainURL}/home`);
            } else {
              this.displayOTPB = false;
            }
          }, 800);
        }
      },
        (error) => {
          console.log(error)
          this.toastrService.error(error.message);
        })
    } else {
      this.isOTPsubmit = true;
    }


  }

  closeAll(){
    this.varifyApproved = false;
    this.displayOTPB = false;
     this.appServices.logout();
    this.router.navigateByUrl(`/${this.domainURL}/home`);
  }

  timer(minute) {
    // let minute = 1;
    let seconds: number = minute * 60;
    let textSec: any = "0";
    let statSec: number = 60;

    const prefix = minute < 10 ? "0" : "";

    const timer = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      if (statSec < 10) {
        textSec = "0" + statSec;
      } else textSec = statSec;

      this.display = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;
      if (seconds == 0) {
        this.resendOtplLink = true;
        this.edit = true;
        console.log("finished");
        clearInterval(timer);

      }

    }, 1000);
  }

  inputsArraY = ['1', '2', '3', '4'];
  public focusNext(e) {
    console.log('here');
    //if(e.target.id<4){
    try {
      for (let i = 0; i < this.inputsArraY.length; i++) {
        console.log(e.target.id, this.inputsArraY[i], "====", e.target.value)
        if ((e.target.id === this.inputsArraY[i] && e.target.value)) {

          let element: any = document.getElementById(`${this.inputsArraY[i + 1]}`);
          element.focus();
        }
        if ((e.target.id === this.inputsArraY[i]) && (e.target.value == '' || e.target.value == undefined)) {
          console.log(e.target.value, "value")
          let element: any = document.getElementById(`${this.inputsArraY[i - 1]}`);
          element.focus();
        }
      }
    } catch (error) {
      console.log(error);

    }

    // }
  }


  resendOTP() {
    let params: any = {};
    params.phoneNumber = this.OTPForm.controls['phone'].value;
    params.customerId = this.customerDetails.id;
    this.appService.resendOTP(params).subscribe(res => {
      this.resendOtplLink = false;
      this.edit = true;
      this.timer(3);
      this.toastrService.success('OTP resend successfully')
    })
  }

  onEditPhone() {
    document.getElementById('phoneNoumber').focus();
    this.edit = false;
  }

  changePhoneNumber() {
    let params: any = {};
    params.userId = this.customerDetails.id;
    params.phoneNumber = this.OTPForm.controls['phone'].value;
    if (this.OTPForm.controls['phone'].valid) {
      this.appService.changePhoneNumber(params).subscribe({
        next: (res) => {
          this.resendOtplLink = false;
          this.edit = true;
          this.timer(3);
        },
        error: (res) => {

        }
      })

    } else {
      this.isOTPsubmit = true;
    }

  }



}

@NgModule({
  declarations: [OTPverificationComponent],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    DialogModule,
    CommonModule
  ],
  providers: [],
  exports: [OTPverificationComponent]
})

export class OTPmodule {

}
