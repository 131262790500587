import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ApplayoutComponent } from './app-layout/app-layout.component';
import {PageNotfound} from './pages/page-404/page-not-found';
import {BookResolverService} from './appServices/resolve.service';
import { GlobalService } from './appServices/globalService';
import { TabDirective } from './shared/tab.directive';

function loadingRoutes() {
  if (!(window.location.hostname === 'margmart.com' || window.location.hostname === 'www.margmart.com')) {
    // if (window.location.hostname !== 'margmartuatui.azurewebsites.net') {

    return {
      component: ApplayoutComponent,
      resolve: {
        data: BookResolverService
      },
      children: [
        { path: '', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule) },
        { path: 'home', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule) },
        { path: 'cart', loadChildren: () => import('./pages/cart/cart.module').then(m => m.CartModule) },
        { path: 'product', loadChildren: () => import('./pages/product/product.module').then(m => m.ProductModule) },
        { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule) },
        { path: 'reset-password', loadChildren: () => import('./pages/reset-password/reset-password.component').then(m => m.ResetPasswordModule) },
        { path: 'verify-email', loadChildren: () => import('./pages/verify-email/verify-email.component').then(m => m.VerifyEmailModule) },
        { path: 'register', loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterModule) },
        { path: 'productDetail/:id', loadChildren: () => import('./pages/product-detail/product-detail.module').then(m => m.ProductDetailModule) },
        { path: 'about', loadChildren: () => import('./pages/about/about.module').then(m => m.AboutModule) },
        { path: 'checkout', loadChildren: () => import('./pages/checkout/checkout.module').then(m => m.CheckoutModule) },
        { path: 'orderpay', loadChildren: () => import('./pages/checkout/checkout.module').then(m => m.CheckoutModule) },
        { path: 'compare', loadChildren: () => import('./pages/compare/compare.module').then(m => m.CompareModule) },
        { path: 'contact', loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactModule) },
        { path: 'deals', loadChildren: () => import('./pages/deals/deals.module').then(m => m.DealsModule) },
        { path: 'editProfile', loadChildren: () => import('./pages/edit-profile/edit-profile.module').then(m => m.EditProfileModule) },
        { path: 'forgetPassword', loadChildren: () => import('./pages/forget-password/forget-password.module').then(m => m.ForgetPasswordModule) },
        { path: 'orderHistory', loadChildren: () => import('./pages/order-history/order-history.module').then(m => m.OrderHistoryModule) },
        { path: 'termsCondition', loadChildren: () => import('./pages/term-condition/term-condition.module').then(m => m.TermConditionModule) },
        { path: 'thankyou', loadChildren: () => import('./pages/thankyou/thankyou.module').then(m => m.ThankyouModule) },
        { path: 'wishLists', loadChildren: () => import('./pages/wish-lists/wish-lists.module').then(m => m.WishListsModule) },
        { path: 'queryList', loadChildren: () => import('./pages/customer-query/customer-query.component').then(m => m.QueryModule) },
        { path: '**', component: PageNotfound },
      ]
    }
  } else {
    return { loadChildren: () => import('./pages/landing-page/landing-page.module').then(m => m.LandingPageModule) }
  }
}

const routes: Routes = [
  {path: '', ...loadingRoutes()},
  {path: '', loadChildren: () => import('./pages/landing-page/landing-page.module').then(m => m.LandingPageModule) },
  {
    path:':sorturl',
    component: ApplayoutComponent,
    resolve: {
      data: BookResolverService
    },
    children:[
      { path: '', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule) },
      { path: 'home', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule) },
      { path: 'cart', loadChildren: () => import('./pages/cart/cart.module').then(m => m.CartModule) }, 
      { path: 'product', loadChildren: () => import('./pages/product/product.module').then(m => m.ProductModule) },
      { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule) },
      { path: 'reset-password', loadChildren: () => import('./pages/reset-password/reset-password.component').then(m => m.ResetPasswordModule) },
      { path: 'verify-email', loadChildren: () => import('./pages/verify-email/verify-email.component').then(m => m.VerifyEmailModule) },
      { path: 'register', loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterModule) },
      { path: 'productDetail/:id', loadChildren: () => import('./pages/product-detail/product-detail.module').then(m => m.ProductDetailModule) },
      { path: 'about', loadChildren: () => import('./pages/about/about.module').then(m => m.AboutModule) },
      { path: 'checkout', loadChildren: () => import('./pages/checkout/checkout.module').then(m => m.CheckoutModule) },
      { path: 'orderpay', loadChildren: () => import('./pages/checkout/checkout.module').then(m => m.CheckoutModule) },
      { path: 'compare', loadChildren: () => import('./pages/compare/compare.module').then(m => m.CompareModule) },
      { path: 'contact', loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactModule) },
      { path: 'deals', loadChildren: () => import('./pages/deals/deals.module').then(m => m.DealsModule) },
      { path: 'editProfile', loadChildren: () => import('./pages/edit-profile/edit-profile.module').then(m => m.EditProfileModule) },
      { path: 'forgetPassword', loadChildren: () => import('./pages/forget-password/forget-password.module').then(m => m.ForgetPasswordModule) },
      { path: 'orderHistory', loadChildren: () => import('./pages/order-history/order-history.module').then(m => m.OrderHistoryModule) },
      { path: 'termsCondition', loadChildren: () => import('./pages/term-condition/term-condition.module').then(m => m.TermConditionModule) },
      { path: 'thankyou', loadChildren: () => import('./pages/thankyou/thankyou.module').then(m => m.ThankyouModule) },
      { path: 'wishLists', loadChildren: () => import('./pages/wish-lists/wish-lists.module').then(m => m.WishListsModule) },
      { path: 'queryList', loadChildren: () => import('./pages/customer-query/customer-query.component').then(m => m.QueryModule) },
      { path: '**', component: PageNotfound },
    ]
  },
  
];
@NgModule({
  declarations:[TabDirective],
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule,TabDirective]
})
export class AppRoutingModule { }
