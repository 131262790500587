<div>
    <a href="javascript:void(0)" class="overlay" onclick="closeWishlist()"></a>
    <div class="cart-inner">
      <div class="cart_top">
        <h3>my wishlist</h3>
        <div class="close-cart">
          <a href="javascript:void(0)" onclick="closeWishlist()">
            <i class="fa fa-times" aria-hidden="true"></i>
          </a>
        </div>
      </div>
      <div class="cart_media">
        <ul class="cart_product">
          <li>
            <div class="media">
              <a href="product.html">
                <img alt="megastore1" class="me-3" src="assets/images/cosmetic/product/1.jpg">
              </a>
              <div class="media-body">
                <a href="product.html">
                  <h4>redmi not 3</h4>
                </a>
                <h6>
                  $80.00 <span>$120.00</span>
                </h6>
                <div class="addit-box">
                  <div class="qty-box">
                    <div class="input-group">
                      <button class="qty-minus"></button>
                      <input class="qty-adj form-control" type="number" value="1" />
                      <button class="qty-plus"></button>
                    </div>
                  </div>
                  <div class="pro-add">
                    <a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#edit-product">
                      <i data-feather="edit"></i>
                    </a>
                    <a href="javascript:void(0)">
                      <i data-feather="trash-2"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="media">
              <a href="product.html">
                <img alt="megastore1" class="me-3" src="assets/images/cosmetic/product/2.jpg">
              </a>
              <div class="media-body">
                <a href="product.html">
                  <h4>Double Door Refrigerator</h4>
                </a>
                <h6>
                  $80.00 <span>$120.00</span>
                </h6>
                <div class="addit-box">
                  <div class="qty-box">
                    <div class="input-group">
                      <button class="qty-minus"></button>
                      <input class="qty-adj form-control" type="number" value="1" />
                      <button class="qty-plus"></button>
                    </div>
                  </div>
                  <div class="pro-add">
                    <a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#edit-product">
                      <i data-feather="edit"></i>
                    </a>
                    <a href="javascript:void(0)">
                      <i data-feather="trash-2"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="media">
              <a href="product.html">
                <img alt="megastore1" class="me-3" src="assets/images/cosmetic/product/3.jpg">
              </a>
              <div class="media-body">
                <a href="product.html">
                  <h4>woman hande bag</h4>
                </a>
                <h6>
                  $80.00 <span>$120.00</span>
                </h6>
                <div class="addit-box">
                  <div class="qty-box">
                    <div class="input-group">
                      <button class="qty-minus"></button>
                      <input class="qty-adj form-control" type="number" value="1" />
                      <button class="qty-plus"></button>
                    </div>
                  </div>
                  <div class="pro-add">
                    <a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#edit-product">
                      <i data-feather="edit"></i>
                    </a>
                    <a href="javascript:void(0)">
                      <i data-feather="trash-2"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <ul class="cart_total">
          <li>
            subtotal : <span>$1050.00</span>
          </li>
          <li>
            shpping <span>free</span>
          </li>
          <li>
            taxes <span>$0.00</span>
          </li>
          <li>
            <div class="total">
              total<span>$1050.00</span>
            </div>
          </li>
          <li>
            <div class="buttons">
              <a [routerLink]="['wishLists']" routerLinkActive="active" class="btn btn-solid btn-block btn-md">view wislist</a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>