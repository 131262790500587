<!-- footer start -->
<div *ngIf="templateId == 1">
  <footer>
    <div class="footer1">
      <div class="custom-container">
        <div class="row">
          <div class="col-12">
            <div class="footer-main">
              <div class="footer-box" *ngIf="!errorDataContact">
                <div class="footer-title mobile-title">
                  <!-- <h5> about us</h5> -->
                </div>
                <div class="footer-contant">
                  <div class="footer-logo">
                    <a routerLink="/{{domainURL}}/home">
                      <img [src]="footerAllLogo" class="img-fluid" alt="logo">
                    </a>
                  </div>
                  <p [innerHTML]="footerData?.pageShortDescription"></p>
                  <div class="social-icons">
                    <a *ngFor="let social of socialArray" [href]="social?.socialLink" target="_blank">
                      <img [src]="globalService.imgUrl(social?.socialImageContainer, social?.socialImageName, 60, 60)"
                        class="img-fluid social-image" alt="">
                    </a>
                  </div>
                  <!-- <ul class="sosiyal">
                    <li *ngIf="footerData?.socialFacebook" title="Facebook"><a href="{{footerData?.socialFacebook}}" target="_blank"><i class="fa fa-facebook"></i></a></li>
                    <li *ngIf="footerData?.socialYoutube" title="Youtube"><a href="{{footerData?.socialYoutube}}" target="_blank"><i class="fa fa-youtube-play"></i></a></li>
                    <li *ngIf="footerData?.socialTwitter" title="Twitter"><a href="{{footerData?.socialTwitter}}" target="_blank"><i class="fa fa-twitter"></i></a></li>
                    <li *ngIf="footerData?.socialInstagram" title="Instagram"><a href="{{footerData?.socialInstagram}}" target="_blank"><i class="fa fa-instagram"></i></a></li>
                    <li *ngIf="footerData?.socialLinkdin"  title="Linkdin"><a href="{{footerData?.socialLinkdin}}" target="_blank" style="color: rgb(78, 113, 239)"><i class="fa fa-linkedin"></i></a></li>
                    <li *ngIf="footerData?.socialPintrest" title="Printrest"><a href="{{footerData?.socialPintrest}}" style="color: red;" target="_blank"><i class="fa fa-pinterest"></i></a></li>
                    <li><a href="{{footerData?.socialFacebook}}"><i class="fa fa-rss"></i></a></li>
                  </ul> -->
                  <h5 class="mt-3 mb-2 cursor" (click)="displayTiming = true"><i class="fa fa-clock-o"></i> Opening
                    Hours :<span *ngIf="(timing?.whole_day_start && timing?.whole_day_end)"
                      style="margin-bottom: 5px;cursor: pointer;color: black;font-size: 12px;">{{timing?.whole_day_start}}
                      - {{timing?.whole_day_end}}</span>
                    <span *ngIf="!(timing?.whole_day_start && timing?.whole_day_end)"
                      style="margin-bottom: 5px;cursor: pointer;color: black;font-size: 12px;">
                      {{timing?.morning_start}} - {{timing?.evening_end}}</span> <i class="fa fa-caret-down ml-2"
                      style="margin-left: 10px; color: black;" aria-hidden="true"></i>
                  </h5>

                  <p style="margin-bottom: 5px;cursor: pointer;"> Today: {{day}}:
                    <span *ngIf="officeOpen == true"> {{timing?.whole_day_start}} - {{timing?.whole_day_end}}</span>
                    <span *ngIf="officeOpen == false"> Whole Day Closed</span>
                  </p>

                </div>
              </div>
              <div class="footer-box account" *ngIf="!errorDataContact">
                <div class="footer-title">
                  <h5>My account</h5>
                </div>
                <div class="footer-contant">
                  <ul>
                    <li><a (click)="about()">about us</a></li>
                    <li><a (click)="getEditProfleData()">My Account</a></li>
                    <li><a (click)="getOrderHistoryData()">Orders Tracking</a></li>
                    <li><a (click)="termsCondition()">Terms Of Use</a></li>
                  </ul>
                </div>
              </div>
              <div class="footer-box contact" *ngIf="!errorDataContact">
                <div class="footer-title">
                  <h5>contact us</h5>
                </div>
                <div class="footer-contant">
                  <ul class="contact-list">
                    <li><i class="fa fa-map-marker"></i><span class="bold"
                        style="font-size: 16px;line-height: 20px;">{{footerData?.companyName}}</span><br>
                      {{footerData?.address}},{{footerData?.city}}-{{footerData?.pincode}}</li>
                    <li><i class="fa fa-phone"></i>call us: <span>{{footerData?.phoneNumber}}</span></li>
                    <li><i class="fa fa-envelope-o"></i>{{footerData?.email}}</li>
                    <li class="d-sm-content">
                      <!-- <a  class="btn-sm btn-solid btn-sm"  style="margin-right:10px;" title="Coming soon">Contact Marg</a> -->
                      <a routerLink="/{{domainURL}}/contact" class="btn-sm btn-solid btn-sm"
                        [ngStyle]="{'background-color': (templateId == 1 ? '#0066cb': '#9bc03b')}"
                        (click)="onTop()">Contact Supplier </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="footer-box contact" *ngIf="errorDataContact">
                <div class="footer-title">
                  <h5>contact us</h5>
                </div>
                <div class="footer-contant">
                  <ul class="contact-list">
                    <li><i class="fa fa-map-marker"></i><span class="bold"
                        style="font-size: 16px;line-height: 20px;">{{errorDataContact?.companyName}}</span><br>
                      {{errorDataContact?.address}},{{errorDataContact?.city}}-{{errorDataContact?.pincode}}</li>
                    <li><i class="fa fa-phone"></i>call us: <span>{{errorDataContact?.phoneNumber}}</span></li>
                    <li><i class="fa fa-envelope-o"></i>{{errorDataContact?.email}}</li>
                  </ul>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="subfooter dark-footer ">
      <div class="container">
        <div class="row">
          <div class="col-xl-12 col-md-12 col-sm-12">
            <div class="text-center">
              <p>&copy;2024 Copyright by Marg Mart, All Right Reserved.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</div>
<!-- footer end -->

<!-- template-2 starts here -->
<div *ngIf="templateId == 2">
  <footer>
    <div class="footer1">
      <div class="custom-container">
        <div class="row">
          <div class="col-12">
            <div class="footer-main">
              <div class="footer-box" *ngIf="!errorDataContact">
                <div class="footer-title mobile-title">
                  <!-- <h5> about us</h5> -->
                </div>
                <div class="footer-contant">
                  <div class="footer-logo">
                    <a routerLink="/{{domainURL}}/home">
                      <img [src]="footerAllLogo" class="img-fluid" alt="logo">
                    </a>
                  </div>
                  <p [innerHTML]="footerData?.pageShortDescription"></p>
                  <div class="social-icons">
                    <a *ngFor="let social of socialArray" [href]="social?.socialLink" target="_blank">
                      <img [src]="globalService.imgUrl(social?.socialImageContainer, social?.socialImageName, 60, 60)"
                        class="img-fluid social-image" alt="">
                    </a>
                  </div>
                  <!-- <ul class="sosiyal">
                       <li *ngIf="footerData?.socialFacebook" title="Facebook"><a href="{{footerData?.socialFacebook}}" target="_blank"><i class="fa fa-facebook"></i></a></li>
                       <li *ngIf="footerData?.socialYoutube" title="Youtube"><a href="{{footerData?.socialYoutube}}" target="_blank"><i class="fa fa-youtube-play"></i></a></li>
                       <li *ngIf="footerData?.socialTwitter" title="Twitter"><a href="{{footerData?.socialTwitter}}" target="_blank"><i class="fa fa-twitter"></i></a></li>
                       <li *ngIf="footerData?.socialInstagram" title="Instagram"><a href="{{footerData?.socialInstagram}}" target="_blank"><i class="fa fa-instagram"></i></a></li>
                       <li *ngIf="footerData?.socialLinkdin"  title="Linkdin"><a href="{{footerData?.socialLinkdin}}" target="_blank" style="color: rgb(78, 113, 239)"><i class="fa fa-linkedin"></i></a></li>
                       <li *ngIf="footerData?.socialPintrest" title="Printrest"><a href="{{footerData?.socialPintrest}}" style="color: red;" target="_blank"><i class="fa fa-pinterest"></i></a></li>
                       <li><a href="{{footerData?.socialFacebook}}"><i class="fa fa-rss"></i></a></li>
                     </ul> -->
                  <h5 class="mt-3 mb-2 cursor" (click)="displayTiming = true"><i class="fa fa-clock-o"></i> Opening
                    Hours :<span *ngIf="(timing?.whole_day_start && timing?.whole_day_end)"
                      style="margin-bottom: 5px;cursor: pointer;color: black;font-size: 12px;">{{timing?.whole_day_start}}
                      - {{timing?.whole_day_end}}</span>
                    <span *ngIf="!(timing?.whole_day_start && timing?.whole_day_end)"
                      style="margin-bottom: 5px;cursor: pointer;color: black;font-size: 12px;">
                      {{timing?.morning_start}} - {{timing?.evening_end}}</span> <i class="fa fa-caret-down ml-2"
                      style="margin-left: 10px; color: black;" aria-hidden="true"></i>
                  </h5>

                  <p style="margin-bottom: 5px;cursor: pointer;"> Today: {{day}}:
                    <span *ngIf="officeOpen == true"> {{timing?.whole_day_start}} - {{timing?.whole_day_end}}</span>
                    <span *ngIf="officeOpen == false"> Whole Day Closed</span>
                  </p>

                </div>
              </div>
              <div class="footer-box account" *ngIf="!errorDataContact">
                <div class="footer-title">
                  <h5>my account</h5>
                </div>
                <div class="footer-contant">
                  <ul>
                    <li><a (click)="about()">about us</a></li>
                    <li><a (click)="getEditProfleData()">My Account</a></li>
                    <li><a (click)="getOrderHistoryData()">Orders Tracking</a></li>
                    <li><a (click)="termsCondition()">Terms Of Use</a></li>
                  </ul>
                </div>
              </div>
              <div class="footer-box contact" *ngIf="!errorDataContact">
                <div class="footer-title">
                  <h5>contact us</h5>
                </div>
                <div class="footer-contant">
                  <ul class="contact-list">
                    <li><i class="fa fa-map-marker"></i><span class="bold"
                        style="font-size: 16px;line-height: 20px;">{{footerData?.companyName}}</span><br>
                      {{footerData?.address}},{{footerData?.city}}-{{footerData?.pincode}}</li>
                    <li><i class="fa fa-phone"></i>call us: <span>{{footerData?.phoneNumber}}</span></li>
                    <li><i class="fa fa-envelope-o"></i>{{footerData?.email}}</li>
                    <li class="d-sm-content">
                      <!-- <a  class="btn-sm btn-solid btn-sm"  style="margin-right:10px;" title="Coming soon">Contact Marg</a> -->
                      <a routerLink="/{{domainURL}}/contact" class="btn-sm btn-solid btn-sm"
                        [ngStyle]="{'background-color': (templateId == 1 ? '#0066cb': '#9bc03b')}"
                        (click)="onTop()">Contact Supplier </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="footer-box contact" *ngIf="errorDataContact">
                <div class="footer-title">
                  <h5>contact us</h5>
                </div>
                <div class="footer-contant">
                  <ul class="contact-list">
                    <li><i class="fa fa-map-marker"></i><span class="bold"
                        style="font-size: 16px;line-height: 20px;">{{errorDataContact?.companyName}}</span><br>
                      {{errorDataContact?.address}},{{errorDataContact?.city}}-{{errorDataContact?.pincode}}</li>
                    <li><i class="fa fa-phone"></i>call us: <span>{{errorDataContact?.phoneNumber}}</span></li>
                    <li><i class="fa fa-envelope-o"></i>{{errorDataContact?.email}}</li>
                  </ul>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="subfooter dark-footer ">
      <div class="container">
        <div class="row">
          <div class="col-xl-12 col-md-12 col-sm-12">
            <div class="text-center">
              <p>&copy;2024 Copyright by Marg Mart, All Right Reserved.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</div>

<!-- Template 3 start here -->
<div *ngIf="templateId == 3">
  <footer>
    <div class="footer1" style="background-color: #202325; color: white;">
      <div class="custom-container">
        <div class="row">
          <div class="col-12">
            <div class="footer-main">
              <div class="footer-box" *ngIf="!errorDataContact">
                <div class="footer-title mobile-title">
                  <!-- <h5> about us</h5> -->
                </div>
                <div class="footer-contant">
                  <div class="footer-logo">
                    <a routerLink="/{{domainURL}}/home">
                      <img [src]="footerAllLogo" class="img-fluid" alt="logo">
                    </a>
                  </div>
                  <p [innerHTML]="footerData?.pageShortDescription"></p>
                  <div class="social-icons">
                    <a *ngFor="let social of socialArray" [href]="social?.socialLink" target="_blank">
                      <img [src]="globalService.imgUrl(social?.socialImageContainer, social?.socialImageName, 60, 60)"
                        class="img-fluid social-image" alt="">
                    </a>
                  </div>
                  <!-- <ul class="sosiyal">
                         <li class="fb" *ngIf="footerData?.socialFacebook" title="Facebook"><a href="{{footerData?.socialFacebook}}" target="_blank"><i class="fa fa-facebook"></i></a></li>
                         <li class="youtube" *ngIf="footerData?.socialYoutube" title="Youtube"><a href="{{footerData?.socialYoutube}}" target="_blank"><i class="fa fa-youtube-play"></i></a></li>
                         <li class="twitter" *ngIf="footerData?.socialTwitter" title="Twitter"><a href="{{footerData?.socialTwitter}}" target="_blank"><i class="fa fa-twitter"></i></a></li>
                         <li class="instagram" *ngIf="footerData?.socialInstagram" title="Instagram"><a href="{{footerData?.socialInstagram}}" target="_blank"><i class="fa fa-instagram"></i></a></li>
                         <li class="link" *ngIf="footerData?.socialLinkdin"  title="Linkdin"><a href="{{footerData?.socialLinkdin}}" target="_blank" style="color: rgb(78, 113, 239)"><i class="fa fa-linkedin"></i></a></li>
                         <li class="pinterst" *ngIf="footerData?.socialPintrest" title="pinterst"><a href="{{footerData?.socialPintrest}}" style="color: red;" target="_blank"><i class="fa fa-pinterest"></i></a></li>
                         <li><a href="{{footerData?.socialFacebook}}"><i class="fa fa-rss"></i></a></li>
                       </ul> -->
                  <h5 style="color: white;" class="mt-3 mb-2 cursor" (click)="displayTiming = true"><i
                      class="fa fa-clock-o"></i> Opening Hours :<span
                      *ngIf="(timing?.whole_day_start && timing?.whole_day_end)"
                      style="margin-bottom: 5px;cursor: pointer;color: white;font-size: 12px;">&nbsp;{{timing?.whole_day_start}}
                      - {{timing?.whole_day_end}}</span>
                    <span *ngIf="!(timing?.whole_day_start && timing?.whole_day_end)"
                      style="margin-bottom: 5px;cursor: pointer;font-size: 12px;"> {{timing?.morning_start}} -
                      {{timing?.evening_end}}</span> <i class="fa fa-caret-down ml-2"
                      style="margin-left: 10px; color: white;" aria-hidden="true"></i>
                  </h5>

                  <p style="margin-bottom: 5px;cursor: pointer; color: white;"> Today: {{day}}:
                    <span *ngIf="officeOpen == true"> {{timing?.whole_day_start}} - {{timing?.whole_day_end}}</span>
                    <span *ngIf="officeOpen == false"> Whole Day Closed</span>
                  </p>

                </div>
              </div>
              <div class="footer-box account" *ngIf="!errorDataContact">
                <div class="footer-title">
                  <h5 style="color: white;">my account</h5>
                </div>
                <div class="footer-contant">
                  <ul>
                    <li><a (click)="about()">about us</a></li>
                    <li><a (click)="getEditProfleData()">My Account</a></li>
                    <li><a (click)="getOrderHistoryData()">Orders Tracking</a></li>
                    <li><a (click)="termsCondition()">Terms Of Use</a></li>
                  </ul>
                </div>
              </div>
              <div class="footer-box contact" *ngIf="!errorDataContact">
                <div class="footer-title">
                  <h5 style="color: white;">contact us</h5>
                </div>
                <div class="footer-contant">
                  <ul class="contact-list">
                    <li><i class="fa fa-map-marker"></i><span class="bold"
                        style="font-size: 16px;line-height: 20px;">{{footerData?.companyName}}</span><br>
                      {{footerData?.address}},{{footerData?.city}}-{{footerData?.pincode}}</li>
                    <li><i class="fa fa-phone"></i>call us: <span>{{footerData?.phoneNumber}}</span></li>
                    <li><i class="fa fa-envelope-o"></i>{{footerData?.email}}</li>
                    <li class="d-sm-content">
                      <!-- <a  class="btn-sm btn-solid btn-sm"  style="margin-right:10px;" title="Coming soon">Contact Marg</a> -->
                      <a routerLink="/{{domainURL}}/contact" class="btn-sm btn-solid btn-sm"
                        style="background-color: #FF324D;" (click)="onTop()">Contact Supplier </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="footer-box contact" *ngIf="errorDataContact">
                <div class="footer-title">
                  <h5>contact us</h5>
                </div>
                <div class="footer-contant">
                  <ul class="contact-list">
                    <li><i class="fa fa-map-marker"></i><span class="bold"
                        style="font-size: 16px;line-height: 20px;">{{errorDataContact?.companyName}}</span><br>
                      {{errorDataContact?.address}},{{errorDataContact?.city}}-{{errorDataContact?.pincode}}</li>
                    <li><i class="fa fa-phone"></i>call us: <span>{{errorDataContact?.phoneNumber}}</span></li>
                    <li><i class="fa fa-envelope-o"></i>{{errorDataContact?.email}}</li>
                  </ul>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="subfooter dark-footer" style="background-color: #202325; color: white;">
      <div class="container">
        <div class="row">
          <div class="col-xl-12 col-md-12 col-sm-12">
            <div class="text-center">
              <p>&copy;2024 Copyright by Marg Mart, All Right Reserved.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</div>

<!-- manufacturer template djay  -->
<div *ngIf="templateId == 4">
  <footer>
    <div class="footer1 bg-dark text-white">
      <div class="custom-container">
       
            <div class="row">
              <div class="col-md-5 col-lg-4" *ngIf="!errorDataContact">
                <div class="footer-title mobile-title">
                  <!-- <h5> about us</h5> -->
                </div>
                <div class="footer-contant">
                  <div class="footer-logo" style="display: flex;">
                    <a routerLink="/{{domainURL}}/home">
                      <img [src]="footerAllLogo" class="img-fluid" alt="logo">
                    </a>
                    <div>
                      <h5 class="ml-md-2 mb-2 cursor djNewTheme" (click)="displayTiming = true"><i
                          class="fa fa-clock-o"></i> Opening Hours :<span
                          *ngIf="(timing?.whole_day_start && timing?.whole_day_end)"
                          style="margin-bottom: 5px;cursor: pointer;color: white;font-size: 12px;">&nbsp;{{timing?.whole_day_start}}
                          - {{timing?.whole_day_end}}</span>
                        <span *ngIf="!(timing?.whole_day_start && timing?.whole_day_end)"
                          style="margin-bottom: 5px;cursor: pointer;font-size: 12px;"> {{timing?.morning_start}} -
                          {{timing?.evening_end}}</span> <i class="fa fa-caret-down ml-2"
                          style="margin-left: 10px; color: white;" aria-hidden="true"></i>
                      </h5>
                      <p class="ml-md-2" style="margin-bottom: 5px;cursor: pointer; color: white;"> Today: {{day}}:
                        <span *ngIf="officeOpen == true"> {{timing?.whole_day_start}} - {{timing?.whole_day_end}}</span>
                        <span *ngIf="officeOpen == false"> Whole Day Closed</span>
                      </p>
                    </div>
                  </div>
                  <p [innerHTML]="footerData?.pageShortDescription"></p>
                  <div class="social-icons">
                    <a *ngFor="let social of socialArray" [href]="social?.socialLink" target="_blank">
                      <img [src]="globalService.imgUrl(social?.socialImageContainer, social?.socialImageName, 60, 60)"
                        class="img-fluid social-image" alt="">
                    </a>
                  </div>
                  <!-- <h5 style="color: #C9F31D;" class="mt-3 mb-2 cursor" (click)="displayTiming = true" ><i class="fa fa-clock-o"></i> Opening Hours :<span *ngIf="(timing?.whole_day_start && timing?.whole_day_end)" style="margin-bottom: 5px;cursor: pointer;color: white;font-size: 12px;">&nbsp;{{timing?.whole_day_start}} - {{timing?.whole_day_end}}</span>
                         <span *ngIf="!(timing?.whole_day_start && timing?.whole_day_end)" style="margin-bottom: 5px;cursor: pointer;font-size: 12px;"> {{timing?.morning_start}} - {{timing?.evening_end}}</span> <i class="fa fa-caret-down ml-2"  style="margin-left: 10px; color: white;" aria-hidden="true"></i></h5>
     
                        <p  style="margin-bottom: 5px;cursor: pointer; color: white;"> Today: {{day}}:
                         <span *ngIf="officeOpen == true"> {{timing?.whole_day_start}} - {{timing?.whole_day_end}}</span>
                         <span *ngIf="officeOpen == false"> Whole Day Closed</span>
                       </p> -->

                </div>
              </div>
              <div class="col-md-3 col-lg-4 account newThemeFourFtr" *ngIf="!errorDataContact">
                <div class="footer-title">
                  <h5 class="djNewTheme">my account</h5>
                </div>
                <div class="footer-contant">
                  <ul>
                    <li><a (click)="about()">about us</a></li>
                    <li><a (click)="getEditProfleData()">My Account</a></li>
                    <li><a (click)="getOrderHistoryData()">Orders Tracking</a></li>
                    <li><a (click)="termsCondition()">Terms Of Use</a></li>
                  </ul>
                </div>
              </div>
              <div class="col-md-4 col-lg-4 contact newThemeFourFtr" *ngIf="!errorDataContact">
                <div class="footer-title">
                  <h5 class="djNewTheme">Contact us</h5>
                </div>
                <div class="footer-contant">
                  <ul class="contact-list">
                    <li><i class="fa fa-map-marker djNewTheme"></i><span class="bold"
                        style="font-size: 16px;line-height: 20px;">{{footerData?.companyName}}</span><br>
                      {{footerData?.address}},{{footerData?.city}}-{{footerData?.pincode}}</li>
                    <li><i class="fa fa-phone djNewTheme"></i>call us:
                      <span>{{footerData?.phoneNumber}}</span></li>
                    <li><i class="fa fa-envelope-o djNewTheme"></i>{{footerData?.email}}</li>
                    <li class="d-sm-content">
                      <!-- <a  class="btn-sm btn-solid btn-sm"  style="margin-right:10px;" title="Coming soon">Contact Marg</a> -->
                      <!-- <a
                           routerLink="/{{domainURL}}/contact" class="btn-sm btn-solid btn-sm" style="background-color: #FF324D;" (click)="onTop()">Contact Supplier </a></li> -->
                  </ul>
                </div>
              </div>

              <div class="col-md-4 col-lg-4 contact newThemeFourFtr" *ngIf="errorDataContact">
                <div class="footer-title">
                  <h5>contact us</h5>
                </div>
                <div class="footer-contant">
                  <ul class="contact-list">
                    <li><i class="fa fa-map-marker"></i><span class="bold"
                        style="font-size: 16px;line-height: 20px;">{{errorDataContact?.companyName}}</span><br>
                      {{errorDataContact?.address}},{{errorDataContact?.city}}-{{errorDataContact?.pincode}}</li>
                    <li><i class="fa fa-phone"></i>call us: <span>{{errorDataContact?.phoneNumber}}</span></li>
                    <li><i class="fa fa-envelope-o"></i>{{errorDataContact?.email}}</li>
                  </ul>
                </div>
              </div>

        </div>
      </div>
    </div>


    <div class="subfooter bg-dark text-white border-top border-secondary">
      <div class="container">
        <div class="row">
          <div class="col-xl-12 col-md-12 col-sm-12">
            <div class="text-center">
              <button class="scroll-top scroll-to-target" data-target="html" (click)="scrollToTop()"><span
                  class="fa fa-angle-double-up"></span></button>
              <p>&copy;2024 Copyright by Marg Mart, All Right Reserved.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</div>

<p-dialog header="Login" class="loginScreen" [(visible)]="isLogin" [modal]="true" [draggable]="false">
  <login_modal (LoginSuccess)="loginSuccess($event)" [visible]="hidePopup"></login_modal>
</p-dialog>


<p-dialog header="All Days Timings" [(visible)]="displayTiming" class="timing" [styleClass]="['custom-dialog']"
  [draggable]="false">
  <div
    *ngIf="(timing?.whole_day_start && timing?.whole_day_end  || timing?.whole_day_start == null && timing?.whole_day_end == null) else shiftTiming">
    <table class="shift-time-table" style="width: 100%;">
      <tr>
        <th style="text-align: left;">Days</th>
        <th style="padding: 0 10px;text-align: center;">Timing</th>
      </tr>
      <tr>
        <td style="text-align: left;">Monday</td>
        <td style="padding: 0 20px;" *ngIf="timing?.monday<1 && timing?.whole_day_start == null" colspan="3">Whole day
          open</td>
        <td *ngIf="timing?.whole_day_start !=null && timing?.monday<1" style="padding: 0 20px;" colspan="3">Whole day
          Close </td>
        <td *ngIf="timing?.monday>0">{{timing?.whole_day_start}} - {{timing?.whole_day_end}} </td>
      </tr>
      <tr>
        <td style="text-align: left;">Tuesday</td>
        <td *ngIf="timing?.tuesday<1 && timing?.whole_day_start == null" style="padding: 0 20px;" colspan="3">Whole day
          open</td>
        <td *ngIf="timing?.whole_day_start !=null  && timing?.tuesday<1" style="padding: 0 20px;" colspan="3">Whole day
          Close </td>
        <td *ngIf="timing?.tuesday>0">{{timing?.whole_day_start}}- {{timing?.whole_day_end}} </td>
      </tr>
      <tr>
        <td style="text-align: left;">Wednesday</td>
        <td *ngIf="timing?.wednesday<1 && timing?.whole_day_start == null" style="padding: 0 20px;" colspan="3">Whole
          day open</td>
        <td *ngIf="timing?.whole_day_start != null && timing?.wednesday == 0" style="padding: 0 20px;" colspan="3">Whole
          day Close </td>
        <td *ngIf="timing?.wednesday>0">{{timing?.whole_day_start}}- {{timing?.whole_day_end}} </td>
      </tr>

      <tr>
        <td style="text-align: left;">Thursday </td>
        <td *ngIf="timing?.thursday<1 && timing?.whole_day_start == null" style="padding: 0 20px;" colspan="3">Whole day
          open</td>
        <td *ngIf="timing?.whole_day_start != null && timing?.thursday<1" style="padding: 0 20px;" colspan="3">Whole day
          Close </td>
        <td *ngIf="timing?.thursday>0">{{timing?.whole_day_start}}- {{timing?.whole_day_end}} </td>
      </tr>

      <tr>
        <td style="text-align: left;">Friday</td>
        <td *ngIf="timing?.friday<1 && timing?.whole_day_start == null" style="padding: 0 20px;" colspan="3">Whole day
          open</td>
        <td *ngIf="timing?.whole_day_start !=null && timing?.friday<1" style="padding: 0 20px;" colspan="3">Whole day
          Close </td>
        <td *ngIf="timing?.friday>0">{{timing?.whole_day_start}}- {{timing?.whole_day_end}} </td>
      </tr>

      <tr>
        <td style="text-align: left;">Saturday</td>
        <td *ngIf="timing?.saturday<1 && timing?.whole_day_start == null" style="padding: 0 20px;" colspan="3">Whole day
          open</td>
        <td *ngIf="timing?.whole_day_start !=null && timing?.saturday<1" style="padding: 0 20px;" colspan="3">Whole day
          Close </td>
        <td *ngIf="timing?.saturday>0">{{timing?.whole_day_start}}- {{timing?.whole_day_end}} </td>
      </tr>
      <tr>
        <td style="text-align: left;">Sunday</td>
        <td *ngIf="timing?.sunday<1 && timing?.whole_day_start == null" style="padding: 0 20px;" colspan="3">Whole day
          open</td>
        <td *ngIf="timing?.whole_day_start !=null && timing?.sunday<1" style="padding: 0 20px;" colspan="3">Whole day
          Close </td>
        <td *ngIf="timing?.sunday>0">{{timing?.whole_day_start}}- {{timing?.whole_day_end}} </td>
      </tr>
    </table>
  </div>

  <ng-template #shiftTiming>
    <table class="shift-time-table" style="width: 100%;">
      <tr>
        <th style="text-align: left;">Days</th>
        <th style="padding: 0 10px;">Morning</th>
        <th style="padding: 0 10px;">Afternoon</th>
        <th style="padding: 0 10px;">Evening</th>
      </tr>
      <tr>
        <td style="text-align: left;">Monday</td>
        <td style="padding: 0 20px;" *ngIf="timing?.monday<1 && timing?.morning_start == ' '" colspan="3">Whole day open
        </td>
        <td *ngIf="timing?.morning_start != ' ' && timing?.monday<1" style="padding: 0 20px;" colspan="3">Whole day
          Close </td>
        <td *ngIf="timing?.monday>0">{{timing?.morning_start}} - {{timing?.morning_end}} </td>
        <td *ngIf="timing?.monday>0">{{timing?.afternoon_start}} - {{timing?.afternoon_end}}</td>
        <td *ngIf="timing?.monday>0">{{timing?.evening_start}}- {{timing?.evening_end}}</td>
      </tr>
      <tr>
        <td style="text-align: left;">Tuesday</td>
        <td *ngIf="timing?.tuesday<1 && timing?.morning_start == ' '" style="padding: 0 20px;" colspan="3">Whole day
          open</td>
        <td *ngIf="timing?.morning_start != ' ' && timing?.tuesday<1" style="padding: 0 20px;" colspan="3">Whole day
          Close </td>
        <td *ngIf="timing?.tuesday>0">{{timing?.morning_start}} - {{timing?.morning_end}} </td>
        <td *ngIf="timing?.tuesday>0">{{timing?.afternoon_start}} - {{timing?.afternoon_end}}</td>
        <td *ngIf="timing?.tuesday>0">{{timing?.evening_start}} - {{timing?.evening_end}}</td>
      </tr>
      <tr>
        <td style="text-align: left;">Wednesday</td>
        <td *ngIf="timing?.wednesday<1 && timing?.morning_start == ' '" style="padding: 0 20px;" colspan="3">Whole day
          open</td>
        <td *ngIf="timing?.morning_start != ' ' && timing?.wednesday<1" style="padding: 0 20px;" colspan="3">Whole day
          Close </td>
        <td *ngIf="timing?.wednesday>0">{{timing?.morning_start}} - {{timing?.morning_end}} </td>
        <td *ngIf="timing?.wednesday>0">{{timing?.afternoon_start}} - {{timing?.afternoon_end}}</td>
        <td *ngIf="timing?.wednesday>0">{{timing?.evening_start}} - {{timing?.evening_end}}</td>
      </tr>

      <tr>
        <td style="text-align: left;">Thursday</td>
        <td *ngIf="timing?.thursday<1 && timing?.morning_start == ' '" style="padding: 0 20px;" colspan="3">Whole day
          open</td>
        <td *ngIf="timing?.morning_start != ' ' && timing?.thursday<1" style="padding: 0 20px;" colspan="3">Whole day
          Close </td>
        <td *ngIf="timing?.thursday>0">{{timing?.morning_start}} - {{timing?.morning_end}} </td>
        <td *ngIf="timing?.thursday>0">{{timing?.afternoon_start}} - {{timing?.afternoon_end}}</td>
        <td *ngIf="timing?.thursday>0">{{timing?.evening_start}} - {{timing?.evening_end}}</td>
      </tr>

      <tr>
        <td style="text-align: left;">Friday</td>
        <td *ngIf="timing?.friday<1 && timing?.morning_start == ' '" style="padding: 0 20px;" colspan="3">Whole day open
        </td>
        <td *ngIf="timing?.morning_start != ' ' && timing?.friday<1" style="padding: 0 20px;" colspan="3">Whole day
          Close </td>
        <td *ngIf="timing?.friday>0">{{timing?.morning_start}} - {{timing?.morning_end}} </td>
        <td *ngIf="timing?.friday>0">{{timing?.afternoon_start}} - {{timing?.afternoon_end}}</td>
        <td *ngIf="timing?.friday>0">{{timing?.evening_start}} - {{timing?.evening_end}}</td>
      </tr>

      <tr>
        <td style="text-align: left;">Saturday</td>
        <td *ngIf="timing?.saturday<1 && timing?.morning_start == ' '" style="padding: 0 20px;" colspan="3">Whole day
          open</td>
        <td *ngIf="timing?.morning_start != ' ' && timing?.saturday<1" style="padding: 0 20px;" colspan="3">Whole day
          Close </td>
        <td *ngIf="timing?.saturday>0">{{timing?.morning_start}} - {{timing?.morning_end}} </td>
        <td *ngIf="timing?.saturday>0">{{timing?.afternoon_start}} - {{timing?.afternoon_end}}</td>
        <td *ngIf="timing?.saturday>0">{{timing?.evening_start}} - {{timing?.evening_end}}</td>
      </tr>
      <tr>
        <td style="text-align: left;">Sunday</td>
        <td *ngIf="timing?.sunday<1 && timing?.morning_start == ' '" style="padding: 0 20px;" colspan="3">Whole day open
        </td>
        <td *ngIf="timing?.morning_start != ' ' && timing?.sunday<1" style="padding: 0 20px;" colspan="3">Whole day
          Close </td>
        <td *ngIf="timing?.sunday>0">{{timing?.morning_start}} - {{timing?.morning_end}} </td>
        <td *ngIf="timing?.sunday>0">{{timing?.afternoon_start}} - {{timing?.afternoon_end}}</td>
        <td *ngIf="timing?.sunday>0">{{timing?.evening_start}} - {{timing?.evening_end}}</td>
      </tr>
    </table>
  </ng-template>
</p-dialog>