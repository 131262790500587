<div id="wrapper" [ngStyle]="{'background-color': getBackgroundColor()}">
    <app-header [TemplateId]="templateId"></app-header>
    <main>
        <router-outlet *ngIf="globalService?.vendorInfo"></router-outlet>
        <div *ngIf="errorMessage">
            <img *ngIf="!displayPrice" src="assets/images/Undermaintence.png" alt=""
                class="img-fluid image-design image_zoom_cls-0">
        </div>
    </main>
    <app-footer [TemplateId]="templateId"></app-footer>
</div>