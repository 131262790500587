<a href="javascript:void(0)" class="overlay" onclick="closeCart()"></a>
<div class="cart-inner">
  <div class="cart_top">
    <!-- <h3>my cart</h3> -->
    <div class="close-cart">
      <a href="javascript:void(0)" onclick="closeCart()">
        <i class="fa fa-times" aria-hidden="true"></i>
      </a>
    </div>
  </div>
  <div class="cart_media">
    <ul class="cart_product">
      <li *ngFor = "let product of cartProduct">
        <div class="media">
          <a href="product.html">
            <img alt="{{product.name}}" class="me-3" 
            src="{{baseUrl?.imageUrl}}?width=150&height=150&path={{product?.optionImageData?.containerName}}&name={{product?.optionImageData?.image}}">
          </a>
          <div class="media-body">
            <a href="product.html">
              <h4>{{product.name}}</h4>
            </a>
            <h6>
              ₹ {{product.productPrice}} <strong> ₹ {{product.total}}</strong>
            </h6>
            <div class="addit-box">
              <div class="qty-box">
                <div class="input-group">
                  <button class="qty-minus" (click)="decrease_quantity(product)"></button>
                  <input class="qty-adj form-control" type="number" [value]="product?.quantity" (change)="countPrice(product)" />
                  <button class="qty-plus" (click)="increase_quantity(product)"></button>
                </div>
              </div>
              <div class="pro-add">
                <a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#edit-product">
                  <i data-feather="edit"></i>
                </a>
                <a href="javascript:void(0)">
                  <i data-feather="trash-2"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <ul class="cart_total">
      <li>
        subtotal : <span>{{totalProductAmount}}</span>
      </li>
      <li>
        shpping <span>free</span>
      </li>
      <li>
        taxes <span>$0.00</span>
      </li>
      <li>
        <div class="total">
          total<span>{{totalProductAmount}}</span>
        </div>
      </li>
      <li>
        <div class="buttons">
          <a routerLink="/{{userInfo?.shortUrl}}/cart" routerLinkActive="active" class="btn btn-solid btn-sm">view cart</a>
          <a href="checkout.html" class="btn btn-solid btn-sm ">checkout</a>
        </div>
      </li>
    </ul>
  </div>
</div>