<!--header start-->
<header id="stickyheader" *ngIf="templateId == 1">
  <!-- <div class="mobile-fix-option"></div> -->
  <header class="sticky_strip">
    <div class="container">
      <nav class="bottom-nav">
        <div class="bottom-nav-item">
          <a routerLink="/{{domainURL}}/home" class="bottom-nav-link">
            <i class="fa fa-home"></i>
            <span>Home</span>
          </a>
        </div>
        <div class="bottom-nav-item">
          <a (click)="getWishListData()" class="bottom-nav-link">
            <i class="fa fa-heart"></i>
            <span>Wishlist</span>
          </a>
        </div>
        <div class="bottom-nav-item">
          <div class="item-count-contain inverce" *ngIf="cartItemCount?.cart != 0" style="background-color: orange;width: 46%; border-radius: 15px; margin-left: 64%;">
            {{cartItemCount?.cart}}
          </div>
          <a (click)="getCartData()" class="bottom-nav-link">
            <i class="fa fa-shopping-cart"></i>
            <span>Cart</span>
            
          </a>
        </div>
        <div class="bottom-nav-item">
          <a (click)="getEditProfleData()" class="bottom-nav-link">
            <i class="ti-email" style="color:#fff"></i>
            <span>Generate Query</span>
          </a>
        </div>

        <div class="bottom-nav-item">
          <a (click)="getOrderHistoryData()" class="bottom-nav-link">
            <i class="fa fa-refresh"></i>
            <span>Order History</span>
          </a>
        </div>
      </nav>
    </div>
  </header>
  <div class="top-header2">
    <div class="custom-container">
      <div class="row">
        <div class="logo-block d-lg-none d-block">
          <div class="brand-logo">
            <!-- routerLink="{{shortURl}}/home" use for navigate in home page. -->
            <a>
              <img routerLink="/{{domainURL}}/home" [src]="headAllLogo" class="img-fluid" alt="logo">
            </a>
          </div>
          <div class="top-header-left" style="margin-top: -41px;">
            <ul>
              <li>
                <a href="tel:9999999364" class="phoneNumber"><i class="fa fa-phone"></i>{{footerData?.phoneNumber}}</a>
              </li>
            </ul>
          </div>
          <div class="top-header-left" style="text-align: center;font-size: 12px;margin-top: 10px;">
            <ul>

              <li> <a style="color: #fff;">{{footerData?.companyName}}</a> </li>


            </ul>
          </div>
        </div>
        <div class="col-md-4 col-sm-4 d-none d-lg-block">
          <div class="top-header-left">
            <ul>
              <!-- <li>
                <a href="mailto:info@margmart.com"><i class="fa fa-envelope"></i>Email: info@margmart.com</a>
              </li> -->
              <li>
                <a href="tel:9999999364" class="phoneNumber"><i class="fa fa-phone"></i>{{footerData?.phoneNumber}}</a>
              </li>
              <!-- <li> <a href="#">[{{footerData?.companyName}}]</a> </li> -->

              <!-- <li>
                  <a class="btn-sm bg-white text-primary" data-bs-toggle="modal" href="#exampleModalToggle" role="button">Supplier Details</a>
                </li> -->
            </ul>
          </div>
        </div>

        <div class="col-md-4 col-sm-4 d-none d-lg-block">
          <div class="top-header-left" style="text-align: center;font-size: 16px;">
            <ul>

              <li> <a style="color: #fff;">{{footerData?.companyName}}</a></li>


            </ul>
          </div>
        </div>
        <div class="col-md-4 col-sm-4">
          <div class="top-header-right d-none d-lg-block">
            <ul>
              <!-- <li>
                  <a href="#"><i class="fa fa-share"></i> Compare</a>
                </li> -->
              <!--<li onclick="openWishlist()">
                <a href="javascript:void(0)"><i class="fa fa-heart"></i> Wishlist</a>
              </li>
              <li>
                <a href="order-history.html"><i class="fa fa-history"></i> order history</a>
              </li> -->

              <!-- <li onclick="openAccount()">
                <a href="javascript:void(0)"><img class="rounded-circle" src="assets/images/avatar-s-3.jpg" alt="avatar" width="22" height="22"> &nbsp;&nbsp;Hello Kanika</a>
              </li> -->
              <li class="dropdown ml-2" id="user_profile">

                <a class="rounded-circle" role="button" id="dropdownUser" data-toggle="dropdown" aria-haspopup="true"
                  aria-expanded="false">
                  <span *ngIf="checkTrue" class="text-white">
                    <img alt="avatar" src="assets/images/avatar.jfif" alt="avatar" width="22" height="22"
                      class="rounded-circle"> &nbsp;&nbsp;Hello Guest <span class="sub-arrow"></span>
                  </span>
                  <span *ngIf="checkFalse" class="text-white">
                    <img alt="avatar" *ngIf="(loginUser?.avatarPath != null)"
                      [src]="globalService.imgUrl(loginUser?.avatarPath,loginUser?.avatar,60,60)" alt="avatar"
                      width="22" height="22" class="rounded-circle">
                    <img alt="avatar" *ngIf="loginUser?.avatarPath == null" src="./assets/images/avatar.jfif"
                      alt="avatar" width="22" height="22" class="rounded-circle">
                    &nbsp;&nbsp;{{currentUser?.firstName}} <span class="sub-arrow"></span>
                  </span>
                </a>

                <div class="dropdown-menu pb-2" aria-labelledby="dropdownUser">

                  <div class="">
                    <ul class="list-unstyled">

                      <li *ngIf="checkTrue">
                        <a class="dropdown-item" (click)="openLoginpage()" routerLinkActive="active">
                          <span class="mr-2">

                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24"
                              fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round" class="feather feather-user">
                              <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                              <circle cx="12" cy="7" r="4"></circle>
                            </svg>
                          </span>Login
                        </a>
                      </li>
                      <li *ngIf="checkTrue">
                        <a class="dropdown-item" (click)="openRegisterPage()" routerLinkActive="active">
                          <span class="mr-2">

                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24"
                              fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round" class="feather feather-user">
                              <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                              <circle cx="12" cy="7" r="4"></circle>
                            </svg>
                          </span>Register
                        </a>
                      </li>
                      <li *ngIf="checkFalse">
                        <a class="dropdown-item" routerLink="/{{domainURL}}/editProfile">
                          <span class="mr-2">

                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24"
                              fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round" class="feather feather-user">
                              <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                              <circle cx="12" cy="7" r="4"></circle>
                            </svg>
                          </span>Edit Profile
                        </a>
                      </li>
                      <li *ngIf="checkFalse">
                        <a class="dropdown-item" routerLink="/{{domainURL}}/wishLists">
                          <span class="mr-2">

                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24">
                              <path
                                d="M6.28 3c3.236.001 4.973 3.491 5.72 5.031.75-1.547 2.469-5.021 5.726-5.021 2.058 0 4.274 1.309 4.274 4.182 0 3.442-4.744 7.851-10 13-5.258-5.151-10-9.559-10-13 0-2.676 1.965-4.193 4.28-4.192zm.001-2c-3.183 0-6.281 2.187-6.281 6.192 0 4.661 5.57 9.427 12 15.808 6.43-6.381 12-11.147 12-15.808 0-4.011-3.097-6.182-6.274-6.182-2.204 0-4.446 1.042-5.726 3.238-1.285-2.206-3.522-3.248-5.719-3.248z" />
                            </svg>
                          </span>Wishlist
                        </a>
                      </li>
                      <li *ngIf="checkFalse">
                        <a class="dropdown-item" routerLink="/{{domainURL}}/orderHistory">
                          <span class="mr-2">

                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24">
                              <path
                                d="M24 12c0 6.627-5.373 12-12 12s-12-5.373-12-12h2c0 5.514 4.486 10 10 10s10-4.486 10-10-4.486-10-10-10c-2.777 0-5.287 1.141-7.099 2.977l2.061 2.061-6.962 1.354 1.305-7.013 2.179 2.18c2.172-2.196 5.182-3.559 8.516-3.559 6.627 0 12 5.373 12 12zm-13-6v8h7v-2h-5v-6h-2z" />
                            </svg>
                          </span>Order History
                        </a>
                      </li>
                      <!-- <li *ngIf="checkFalse">
                        <a class="dropdown-item" routerLink="/{{shortURl?.shortUrl}}/404">
                          <span class="mr-2">

                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24">
                              <path
                                d="M12 2c3.196 0 6 2.618 6 5.602 0 3.093-2.493 7.132-6 12.661-3.507-5.529-6-9.568-6-12.661 0-2.984 2.804-5.602 6-5.602m0-2c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z" />
                            </svg>
                          </span>Order Tracking
                        </a>
                      </li>
                      <li *ngIf="checkFalse">
                        <a class="dropdown-item" routerLink="/{{shortURl?.shortUrl}}/404" onclick="mySetting()">
                          <span class="mr-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24"
                              fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round" class="feather feather-settings">
                              <circle cx="12" cy="12" r="3"></circle>
                              <path
                                d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z">
                              </path>
                            </svg></span>Settings
                        </a>
                      </li> -->
                    </ul>
                  </div>
                  <div *ngIf="checkFalse">
                    <!-- <div class="dropdown-divider"></div> -->
                    <ul class="list-unstyled">
                      <li>
                        <a class="dropdown-item border-0" (click)="logout()">
                          <span class="mr-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24"
                              fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round" class="feather feather-power">
                              <path d="M18.36 6.64a9 9 0 1 1-12.73 0"></path>
                              <line x1="12" y1="2" x2="12" y2="12"></line>
                            </svg></span>Sign Out
                        </a>
                      </li>

                    </ul>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="searchbar-main header7">
    <div class="custom-container">
      <div class="row">
        <div class="col-12">
          <div class="header-contain">
            <div class="logo-block d-none d-lg-block">
              <div class="brand-logo">
                <a>
                  <img routerLink="/{{domainURL}}/home" [src]="this.headAllLogo" class="img-fluid" alt="logo">
                </a>
              </div>
            </div>
            <div class="menu-block">
              <nav id="main-nav" class="main-nav-oo">
                <div class="toggle-nav" (click)="sidenav()"><i class="fa fa-bars sidebar-bar"></i></div>
                <ul id="main-menu" class="sm pixelstrap sm-horizontal">
                  <li class="d-flex justify-content-between d-lg-none d-block">
                    <a class="rounded-circle" role="button" id="dropdownUser" data-toggle="dropdown"
                      aria-haspopup="true" aria-expanded="false">
                      <span *ngIf="checkTrue">
                        <img alt="avatar" src="assets/images/avatar.jfif" alt="avatar" width="22" height="22"
                          class="rounded-circle"> &nbsp;&nbsp;Hello Guest
                      </span>
                      <span *ngIf="checkFalse">
                        <img alt="avatar" *ngIf="(loginUser?.avatarPath != null)"
                          [src]="globalService.imgUrl(loginUser?.avatarPath,loginUser?.avatar,60,60)" alt="avatar"
                          width="22" height="22" class="rounded-circle">
                        <img alt="avatar" *ngIf="loginUser?.avatarPath == null" src="./assets/images/avatar.jfif"
                          alt="avatar" width="22" height="22" class="rounded-circle">
                        &nbsp;&nbsp;{{currentUser?.firstName}}
                      </span>
                    </a>
                    <div class="mobile-back">

                      <i class="fa fa-close ps-2" aria-hidden="true"></i>
                    </div>
                  </li>
                  <li *ngIf="checkTrue" class="d-lg-none d-block">
                    <a class="dark-menu-item" (click)="openLoginpage();hideClass()" routerLinkActive="active">
                      Login
                    </a>
                  </li>
                  <li><a class="dark-menu-item" routerLink="/{{domainURL}}/home" routerLinkActive="active"
                      (click)="hideClass()">Home</a></li>
                      <li class="dropdown">
                        <a class="dark-menu-item v" routerLink="/{{domainURL}}/product" routerLinkActive="active" (click)="hideClass()">
                          Products <i class="fa fa-angle-down"></i>
                        </a>
                        <div class="dropdown-menu new-design">
                          <div class="Design-changes scrollable-dropdown">
                            <a class="dark-menu-item v col-lg-4 col-md-4" routerLink="/{{domainURL}}/product" routerLinkActive="active"
                               (click)="hideClass()" style="color: #0066cb;"> All
                            </a>
                            <a *ngFor="let item of categoryList;" class="category-item dark-menu-item v col-lg-4 col-md-4" 
                               routerLink="/{{domainURL}}/product" (click)="hideClass()" [queryParams]="{catId: item.categoryId, brandId: ''}" 
                               routerLinkActive="active">
                              {{item?.name}}
                            </a>
                          </div>
                        </div>
                      </li>
                      <!-- <li class="dropdown">
                        <a class="dark-menu-item v" routerLink="/{{domainURL}}/product" routerLinkActive="active" (click)="hideClass()">
                          Products <i class="fa fa-angle-down"></i>
                        </a>
                        <div class="dropdown-menu new-design">
                          <div class="Design-changes scrollable-dropdown">
                            <a class="dark-menu-item v col-lg-4 col-md-4" routerLink="/{{domainURL}}/product" routerLinkActive="active"
                              (click)="hideClass()"> All</a>
                            <a *ngFor="let item of categoryList;" class="col-lg-4 col-md-4" routerLink="/{{domainURL}}/product" (click)="hideClass()"
                              [queryParams]="{catId: item.categoryId,brandId:''}" routerLinkActive="active">
                              {{item?.name}}
                            </a>
                          </div>
                        </div>
                      </li> -->
                      
                  <li><a class="dark-menu-item" routerLink="/{{domainURL}}/about" routerLinkActive="active"
                      (click)="hideClass()">About Us</a></li>
                  <!-- <li><a class="dark-menu-item" href="#">QR Code</a></li> -->
                  <li><a class="dark-menu-item" routerLink="/{{domainURL}}/contact" routerLinkActive="active"
                      (click)="hideClass()">Contact Us</a></li>

                  <!-- <li *ngIf="checkTrue" class="d-lg-none d-block dark-menu-item">
                    <a class="dark-menu-item" (click)="openRegisterPage()" routerLinkActive="active">
                      Register
                    </a>
                  </li> -->
                  <li *ngIf="checkFalse" class="d-lg-none d-block">
                    <a class="dark-menu-item" routerLink="/{{domainURL}}/editProfile" routerLinkActive="active"
                      (click)="hideClass()">
                      Edit Profile
                    </a>
                  </li>
                  <!-- <li *ngIf="checkFalse" class="d-lg-none d-block">
                    <a class="dark-menu-item" routerLink="/{{shortURl}}/wishLists" routerLinkActive="active"
                      (click)="hideClass()">
                      Wishlist
                    </a>
                  </li>
                  <li *ngIf="checkFalse" class="d-lg-none d-block">
                    <a class="dark-menu-item" routerLink="/{{shortURl}}/orderHistory" routerLinkActive="active"
                      (click)="hideClass()">
                      Order History
                    </a>
                  </li> -->
                  <li *ngIf="checkFalse" class="d-lg-none d-block">
                    <a class="dark-menu-item" (click)="logout();hideClass()" routerLinkActive="active">
                      Sign Out
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
            <div class="icon-block mob_icons_not_show">
              <div class="input-block" id="cus_searchbar">
                <div class="input-box">
                  <!-- <form class="big-deal-form">
                    <div class="input-group ">
                      <input type="text" id="global-search" class="form-control" placeholder="Search a Product"
                        [(ngModel)]="searchTerm" autocomplete="off" [ngModelOptions]="{standalone: true}" (keyup)="searchProducts($event);getGlobelSearch($event);op.toggle($event)"> -->
                      <!-- <i class="fa fa-camera img_search d-none d-lg-block" ></i>
                      <i class="fa fa-microphone voice_search d-none d-lg-block"></i> -->
                      <!-- <span class="search"><i class="fa fa-search" (click)="searchProduct()"></i></span>
                    </div>
                  </form> -->
                  <form class="big-deal-form">
                    <div class="input-group ">
                      <input type="search" style="margin-top: -1%;" id="global-search" class="form-control" placeholder="Search a Product"
                        [(ngModel)]="searchTerm" autocomplete="off" [ngModelOptions]="{standalone: true}" (click)="searchProducts($event);getGlobelSearch($event);" (keyup)="searchProducts($event);getGlobelSearch($event);">
                      <!-- <i class="fa fa-camera img_search d-none d-lg-block" ></i>
                      <i class="fa fa-microphone voice_search d-none d-lg-block"></i> -->
                      <span class="search"><i class="fa fa-search" (click)="searchProduct()"></i></span>
                    </div>                    
                  </form>
                  <div class="resultbox" *ngIf="search_show">
                    <ul *ngFor="let item of searchResult" class="result_ul">
                      <li class="result_li"  style="cursor: pointer;" (click)="onRowSelect(item);">{{item.name}}</li>
                    </ul>
                  </div>   
                  <div class="resultbox" *ngIf="search_show1">
                    <ul class="result_ul">
                      <li class="result_li">No Records Found!</li>
                    </ul>                    
                  </div>
                </div>
              </div>
              <ul class="theme-color">
                <!-- <li class="mobile-cart item-count compare_sticky" data-tippy-content="Compare" data-tippy-placement="bottom">
                    <a href="compare.html">
                      <i class="fa fa-share text-white"></i>
                    </a>
                  
                  </li> -->
                <li title="Wish List" *ngIf="checkFalse" class="mobile-cart item-count history_sticky"
                  data-tippy-content="Wishlist" data-tippy-placement="bottom">
                  <a routerLink="/{{domainURL}}/wishLists">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                      <path
                        d="M12 4.248c-3.148-5.402-12-3.825-12 2.944 0 4.661 5.571 9.427 12 15.808 6.43-6.381 12-11.147 12-15.808 0-6.792-8.875-8.306-12-2.944z" />
                    </svg>
                    <div class="item-count-contain inverce" *ngIf="cartItemCount?.wishlist != 0">
                      {{cartItemCount?.wishlist}}
                    </div>

                  </a>
                </li>

                <li title="Generate Query" *ngIf="checkFalse" class="mobile-cart item-count history_sticky"
                  data-tippy-content="Wishlist" data-tippy-placement="bottom">
                  <a routerLink="/{{domainURL}}/queryList">
                    <i class="ti-email" style="color:#fff"></i>
                    <div class="item-count-contain inverce" *ngIf="cartItemCount?.query != 0">
                      {{cartItemCount?.query}}
                    </div>

                  </a>
                </li>
                <li *ngIf="checkFalse" title="Order History" class="mobile-cart item-count history_sticky"
                  data-tippy-content="Order History" data-tippy-placement="bottom">
                  <a routerLink="/{{domainURL}}/orderHistory">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                      <path
                        d="M24 12c0 6.627-5.373 12-12 12s-12-5.373-12-12h2c0 5.514 4.486 10 10 10s10-4.486 10-10-4.486-10-10-10c-2.777 0-5.287 1.141-7.099 2.977l2.061 2.061-6.962 1.354 1.305-7.013 2.179 2.18c2.172-2.196 5.182-3.559 8.516-3.559 6.627 0 12 5.373 12 12zm-13-6v8h7v-2h-5v-6h-2z" />
                    </svg>
                  </a>

                </li>

                <!-- <li class="mobile-cart item-count" title="Track Order" data-tippy-content="Track Order"
                  data-tippy-placement="bottom">
                  <a href="#">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                      <path
                        d="M12 0c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z" />
                    </svg>
                  </a>
                </li> -->
                <li title="Add To Cart" *ngIf="checkFalse" class="mobile-cart item-count history_sticky"
                  data-tippy-content="Add to Cart" data-tippy-placement="bottom">
                  <a routerLink="/{{domainURL}}/cart">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                      <path
                        d="M10 19.5c0 .829-.672 1.5-1.5 1.5s-1.5-.671-1.5-1.5c0-.828.672-1.5 1.5-1.5s1.5.672 1.5 1.5zm3.5-1.5c-.828 0-1.5.671-1.5 1.5s.672 1.5 1.5 1.5 1.5-.671 1.5-1.5c0-.828-.672-1.5-1.5-1.5zm1.336-5l1.977-7h-16.813l2.938 7h11.898zm4.969-10l-3.432 12h-12.597l.839 2h13.239l3.474-12h1.929l.743-2h-4.195z" />
                    </svg>
                    <div class="item-count-contain inverce" *ngIf="cartItemCount?.cart != 0">
                      {{cartItemCount?.cart}}
                    </div>
                  </a>
                </li>
              </ul>

            </div>
            <div class="toggle-nav" (click)="sidenav()"><i class="fa fa-bars sidebar-bar"></i></div>
          </div>
        </div>
      </div>
    </div>
    <div class="searchbar-input">
      <div class="input-group">
        <span class="input-group-text"><svg version="1.1" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="28.931px" height="28.932px"
            viewBox="0 0 28.931 28.932" style="enable-background:new 0 0 28.931 28.932;" xml:space="preserve">
            <g>
              <path
                d="M28.344,25.518l-6.114-6.115c1.486-2.067,2.303-4.537,2.303-7.137c0-3.275-1.275-6.355-3.594-8.672C18.625,1.278,15.543,0,12.266,0C8.99,0,5.909,1.275,3.593,3.594C1.277,5.909,0.001,8.99,0.001,12.266c0,3.276,1.275,6.356,3.592,8.674c2.316,2.316,5.396,3.594,8.673,3.594c2.599,0,5.067-0.813,7.136-2.303l6.114,6.115c0.392,0.391,0.902,0.586,1.414,0.586c0.513,0,1.024-0.195,1.414-0.586C29.125,27.564,29.125,26.299,28.344,25.518z M6.422,18.111c-1.562-1.562-2.421-3.639-2.421-5.846S4.86,7.983,6.422,6.421c1.561-1.562,3.636-2.422,5.844-2.422s4.284,0.86,5.845,2.422c1.562,1.562,2.422,3.638,2.422,5.845s-0.859,4.283-2.422,5.846c-1.562,1.562-3.636,2.42-5.845,2.42S7.981,19.672,6.422,18.111z" />
            </g>
          </svg></span>
        <input type="text" class="form-control" placeholder="search your product">
        <span class="input-group-text close-searchbar"><svg viewBox="0 0 329.26933 329"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0" />
          </svg></span>
      </div>
    </div>
  </div>
</header>

<!-- Design For New template-2  -->
<header id="header" class="" *ngIf="templateId == 2">
  <div class="header-container">
    <div class="header-banner">
      <div class="container">
        <div class="inner"></div>
      </div>
    </div>
    <nav class="header-nav">
      <div class="topnav">
        <div class="container">
          <div class="inner"></div>
        </div>
      </div>
      <div class="bottomnav">
        <div class="container">
          <div class="inner">
            <div class="row header-top-nav ApRow  has-bg bg-boxed" style="background: no-repeat;"
              data-bg_data=" no-repeat">

              <div class="col-xl-7 col-lg-6 col-md-6 col-sm-6 col-xs-6 col-sp-6 left ApColumn ">
                <div class="col-md-4 col-sm-4" style="margin-top: 16px;">
                  <div class="top-header-left d-none d-lg-block">
                    <ul>
                      <li>
                        <a href="tel:9999999364" class="phoneNumber"><i
                            class="fa fa-phone"></i>{{footerData?.phoneNumber}}</a>
                      </li>
                    </ul>
                  </div>
                    <div class="d-lg-none d-block dj-logo "><a routerLink="/{{domainURL}}/home"><img class="logo img-fluid" [src]="headAllLogo"
                          alt="Bos Medicor"></a>
                          <h3>{{footerData?.companyName}}</h3>
                          
                    </div>
                </div>

                <div class="">
                  <div class="top-header-left forMobileView-template-2 d-none d-lg-block"
                    style="text-align: end;font-size: 16px;margin-top: 12px;">
                    <ul>
                      <li> <a>{{footerData?.companyName}}</a> </li>
                    </ul>
                  </div>
                </div>

              </div>
              <div class="col-xl-5 col-lg-6 col-md-6 col-sm-6 col-xs-6 col-sp-6 right ApColumn ">

                <div class="userinfo-selector links dropdown js-dropdown popup-over ">
                  <!-- <a href="javascript:void(0)" data-toggle="dropdown" class="popup-title " title="Account">
                    <i class="fa fa-user hidden-sm-up"></i>
                    <span class="hidden-xs-down">My Account</span>
                    <i class="fa fa-angle-down"></i>
                  </a> -->
                  <a class="rounded-circle" role="button" id="dropdownUser" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">
                    <span *ngIf="checkTrue">
                      <img alt="avatar" src="assets/images/avatar.jfif" alt="avatar" width="42" height="42"
                        class="rounded-circle"> &nbsp;&nbsp;<span class="sub-arrow"></span>
                    </span>
                    <span *ngIf="checkFalse" style="margin: 2px;">
                      <img alt="avatar" *ngIf="(loginUser?.avatarPath != null)"
                        [src]="globalService.imgUrl(loginUser?.avatarPath,loginUser?.avatar,60,60)" alt="avatar"
                        width="42" height="42" class="rounded-circle">
                      <img alt="avatar" *ngIf="loginUser?.avatarPath == null" src="./assets/images/avatar.jfif"
                        alt="avatar" width="42" height="42" class="rounded-circle">
                      &nbsp;&nbsp;<span class="sub-arrow"></span>
                    </span>
                  </a>
                  <ul class="popup-content dropdown-menu user-info">
                    <li *ngIf="checkTrue">
                      <a class="dropdown-item" (click)="openLoginpage()" routerLinkActive="active">
                        <span class="mr-2">

                          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none"
                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                            class="feather feather-user">
                            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                            <circle cx="12" cy="7" r="4"></circle>
                          </svg>
                        </span>Login
                      </a>
                    </li>
                    <li *ngIf="checkTrue">
                      <a class="dropdown-item" (click)="openRegisterPage()" routerLinkActive="active">
                        <span class="mr-2">

                          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none"
                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                            class="feather feather-user">
                            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                            <circle cx="12" cy="7" r="4"></circle>
                          </svg>
                        </span>Register
                      </a>
                    </li>
                    <li *ngIf="checkFalse">
                      <a class="signin dropdown-item" routerLink="/{{domainURL}}/editProfile"
                        title="Log in to your customer account" rel="nofollow">
                        <span class="mr-2">

                          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none"
                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                            class="feather feather-user">
                            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                            <circle cx="12" cy="7" r="4"></circle>
                          </svg>
                        </span>Edit Profile
                      </a>
                    </li>
                    <li *ngIf="checkFalse">
                      <a class="myacount dropdown-item" routerLink="/{{domainURL}}/wishLists" title="My account"
                        rel="nofollow">
                        <span class="mr-2">

                          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24">
                            <path
                              d="M6.28 3c3.236.001 4.973 3.491 5.72 5.031.75-1.547 2.469-5.021 5.726-5.021 2.058 0 4.274 1.309 4.274 4.182 0 3.442-4.744 7.851-10 13-5.258-5.151-10-9.559-10-13 0-2.676 1.965-4.193 4.28-4.192zm.001-2c-3.183 0-6.281 2.187-6.281 6.192 0 4.661 5.57 9.427 12 15.808 6.43-6.381 12-11.147 12-15.808 0-4.011-3.097-6.182-6.274-6.182-2.204 0-4.446 1.042-5.726 3.238-1.285-2.206-3.522-3.248-5.719-3.248z" />
                          </svg>
                        </span>Wishlist
                      </a>
                    </li>
                    <li *ngIf="checkFalse">
                      <a class="checkout dropdown-item" routerLink="/{{domainURL}}/orderHistory" title="Checkout"
                        rel="nofollow">
                        <span class="mr-2">

                          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24">
                            <path
                              d="M24 12c0 6.627-5.373 12-12 12s-12-5.373-12-12h2c0 5.514 4.486 10 10 10s10-4.486 10-10-4.486-10-10-10c-2.777 0-5.287 1.141-7.099 2.977l2.061 2.061-6.962 1.354 1.305-7.013 2.179 2.18c2.172-2.196 5.182-3.559 8.516-3.559 6.627 0 12 5.373 12 12zm-13-6v8h7v-2h-5v-6h-2z" />
                          </svg>
                        </span>Order History
                      </a>
                    </li>
                    <li *ngIf="checkFalse">
                      <a class="ap-btn-compare dropdown-item" (click)="logout()" title="Compare" rel="nofollow">
                        <span class="mr-2">
                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none"
                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                            class="feather feather-power">
                            <path d="M18.36 6.64a9 9 0 1 1-12.73 0"></path>
                            <line x1="12" y1="2" x2="12" y2="12"></line>
                          </svg></span>Sign Out
                      </a>
                    </li>
                  </ul>
                </div>
                <ul class="header-button-add float-xs-right d-none d-lg-block">
                  <!-- <li> <a class="ap-btn-wishlist">
                      <span></span> <span class="">
                        &nbsp;&nbsp;{{currentUser?.firstName}}</span></a>
                  </li> -->
                </ul>
                <!-- <ul class="header-button-add float-xs-right d-none d-lg-block">
                  <li> <a class="ap-btn-wishlist" (click)="getWishListData()" title="Wishlist" rel="nofollow">
                      <span>Wishlist</span> (<span class="ap-total-wishlist ap-total">
                        {{cartItemCount?.wishlist}}</span>) </a>
                  </li>
                </ul> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <div class="header-top">
      <div class="container">
        <div class="inner">
          <div class="row header-logo-h2 ApRow  has-bg bg-boxed" style="background: no-repeat;"
            data-bg_data=" no-repeat">

            <div class="col-xl-2 col-lg-3 col-md-12 col-sm-12 col-xs-12 col-sp-12 left ApColumn ">
              <div class="header_logo d-none d-lg-block"><a routerLink="/{{domainURL}}/home"><img class="logo img-fluid"  [src]="headAllLogo" class="img-fluid" alt="logo"></a>
              </div>
            </div>
            <div class="col-xl-8 col-lg-8 col-md-8 col-sm-9 col-xs-10 col-sp-10 right ApColumn For-mobileView-searrch">


              <div id="leo_search_block_top" class="block exclusive search-by-category">
                <h4 class="title_block">Search</h4>
                <form [formGroup]="searchForm">
                  <!-- <input type="hidden" name="fc" value="module">
                  <input type="hidden" name="module" value="leoproductsearch">
                  <input type="hidden" name="controller" value="productsearch">
                  <input type="hidden" name="leoproductsearch_static_token" value="3e83918b6704d6f76b5d3a0911c015c1"> -->
                  <div class="block_content clearfix leoproductsearch-content">
                    <div class="leoproductsearch-result">
                      <div class="leoproductsearch-loading cssload-speeding-wheel"></div>
                      <input type="search" style="margin-top: -1%;" id="global-search" class="form-control" placeholder="Enter your Keyword..."
                        [(ngModel)]="searchTerm" autocomplete="off" [ngModelOptions]="{standalone: true}" (click)="searchProducts($event);getGlobelSearch($event);" (keyup)="searchProducts($event);getGlobelSearch($event);">
                    </div>
                    <button type="submit" (click)="searchProductsByone()"
                      class="btn btn-default button button-small"><span><i class="fa fa-search" (click)="searchProduct()"></i></span></button>
                  </div>
                </form>
                <div class="resultbox" *ngIf="search_show">
                  <ul *ngFor="let item of searchResult" class="result_ul">
                    <li class="result_li"  style="cursor: pointer;" (click)="onRowSelect(item);">{{item.name}}</li>
                  </ul>
                </div>   
                <div class="resultbox" *ngIf="search_show1">
                  <ul class="result_ul">
                    <li class="result_li">No Records Found!</li>
                  </ul>                    
                </div>
              </div>
              <script type="text/javascript">
                var blocksearch_type = 'top';
              </script>
                <div class="header mobile-view-cart d-lg-none d-block">
                  <i class="fa fa-shopping-basket" (click)="getCartData()"></i>
                  <span class="cart-products-count size-view">{{cartItemCount?.cart}}</span>
                </div>
            </div>
            <div class="col-xl-2 col-lg-1 col-md-4 col-sm-3 col-xs-2 col-sp-2 right-header ApColumn">

              <div id="_desktop_cart">
                <div class="blockcart cart-preview inactive leo-blockcart show-leo-loading">
                  <div class="header ordreHistory d-none d-lg-block">
                    <a (click)="getOrderHistoryData()" title="Order History">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                        <path
                          d="M24 12c0 6.627-5.373 12-12 12s-12-5.373-12-12h2c0 5.514 4.486 10 10 10s10-4.486 10-10-4.486-10-10-10c-2.777 0-5.287 1.141-7.099 2.977l2.061 2.061-6.962 1.354 1.305-7.013 2.179 2.18c2.172-2.196 5.182-3.559 8.516-3.559 6.627 0 12 5.373 12 12zm-13-6v8h7v-2h-5v-6h-2z" />
                      </svg>
                    </a>
                  </div>
                  <div class="header d-none d-lg-block" title="Wishlist">
                    <i class="fa fa-heart" (click)="getWishListData()"></i>
                    <span class="cart-products-count">{{cartItemCount?.wishlist}}</span>
                  </div>
                  <div class="header d-none d-lg-block" title="Shopping Cart">
                    <i class="fa fa-shopping-cart" (click)="getCartData()"></i>
                    <span class="cart-products-count">{{cartItemCount?.cart}}</span>
                  </div>
                  <div class="header ordreHistory d-none d-lg-block" title="Query">
                    <a (click)="getQueryData()">
                      <i class="ti-email" style="color:#060606"></i>
                      <span class="cart-products-count">{{cartItemCount?.query}}</span>
                    </a>
                  </div>
                  <div class="cssload-piano" style="display: none;">
                    <div class="cssload-rect1"></div>
                    <div class="cssload-rect2"></div>
                    <div class="cssload-rect3"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row header-bottom ApRow  has-bg bg-boxed" style="background: no-repeat;"
            data-bg_data=" no-repeat">

            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-sp-12  ApColumn ">

              <div class="ApMegamenu">
                <nav class="leo-megamenu cavas_menu navbar navbar-default enable-canvas " role="navigation">
                  <div class="navbar-header">
                    <button type="button" class="navbar-toggler hidden-lg-up toggle-nav" data-toggle="collapse"
                      data-target=".megamenu-off-canvas-3193907378" (click)="visibleSidebar1= true;">
                      <span class="sr-only">Toggle navigation</span>
                      ☰
                    </button>
                  </div>

                  <div (click)="hideClass()"
                    class="leo-top-menu collapse navbar-toggleable-md megamenu-off-canvas megamenu-off-canvas-3193907378">
                    <ul class="nav navbar-nav megamenu horizontal display">
                      <li data-menu-type="controller" class="nav-item parent dropdown active">
                        <a class="nav-link" routerLink="/{{domainURL}}/home" target="_self">
                          <span class="menu-title" style="font-weight: 700;">Home</span>
                        </a>

                      </li>
                      <li data-menu-type="category" class="nav-item parent  dropdown aligned-fullwidth">
                        <a class="nav-link has-category" routerLink="/{{domainURL}}/product"
                          target="_self">
                          <span class="menu-title" style="font-weight: 700;">PRODUCTS <i class="fa fa-angle-down"></i></span>
                        </a>
                        <b class="caret"></b>
                        <div class="dropdown-sub dropdown-menu" style="background-color: #f9f9f9;">
                          <div class="dropdown-menu-inner">
                            <div class="row">
                              <div>
                                <a class="category-View" style="margin-left: 16px;font-size: 16px; font-weight: 700;" routerLink="/{{domainURL}}/product" routerLinkActive="active" (click)="hideClass()">
                                  All</a>
                                <div *ngFor="let item of categoryList;" class="col-sm-9 col-xl- col-lg-4 col-md-4 col-6">
                                  <a routerLink="/{{domainURL}}/product" [queryParams]="{catId: item.categoryId,brandId:''}" routerLinkActive="active">
                                    {{item?.name}}
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li data-menu-type="url" class="nav-item  ">
                        <a class="nav-link has-category" routerLink="/{{domainURL}}/about" target="_self">
                          <span class="menu-title" style="font-weight: 700;">About Us</span>
                        </a>
                      </li>
                      <li data-menu-type="url" class="nav-item  ">
                        <a class="nav-link has-category" routerLink="/{{domainURL}}/contact" target="_self">
                          <span class="menu-title" style="font-weight: 700;">Contact Us</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>

<!-- Design For New template-3  -->
<header id="stickyheader" *ngIf="templateId == 3">
  <!-- <div class="mobile-fix-option"></div> -->
  <header class="sticky_strip">
    <div class="container">
      <nav class="bottom-nav">
        <div class="bottom-nav-item">
          <a routerLink="/{{domainURL}}/home" class="bottom-nav-link">
            <i class="fa fa-home"></i>
            <span>Home</span>
          </a>
        </div>
        <div class="bottom-nav-item">
          <a (click)="getWishListData()" class="bottom-nav-link">
            <i class="fa fa-heart"></i>
            <span>Wishlist</span>
          </a>
        </div>
        <div class="bottom-nav-item">
          <div class="item-count-contain inverce" *ngIf="cartItemCount?.cart != 0" style="background-color: orange;width: 46%; border-radius: 15px; margin-left: 64%;">
            {{cartItemCount?.cart}}
          </div>
          <a (click)="getCartData()" class="bottom-nav-link">
            <i class="fa fa-shopping-cart"></i>
            <span>Cart</span>
            
          </a>
        </div>
        <div class="bottom-nav-item">
          <a (click)="getEditProfleData()" class="bottom-nav-link">
            <i class="ti-email" style="color:#fff"></i>
            <span>Generate Query</span>
          </a>
        </div>

        <div class="bottom-nav-item">
          <a (click)="getOrderHistoryData()" class="bottom-nav-link">
            <i class="fa fa-refresh"></i>
            <span>Order History</span>
          </a>
        </div>
      </nav>
    </div>
  </header>
  
  <div class="searchbar-main header7" style="background-color: #FF324D;">
    <div class="custom-container">
      <div class="row">
        <div class="col-12">
          <div class="header-contain">
            <div class="logo-block d-none d-lg-block">
              <div class="brand-logo">
                <a>
                  <img routerLink="/{{domainURL}}/home" [src]="this.headAllLogo" class="img-fluid" alt="logo">
                </a>
              </div>
            </div>
            <div class="menu-block">
              <nav id="main-nav" class="main-nav-oo">
                <div class="toggle-nav" (click)="sidenav()"><i class="fa fa-bars sidebar-bar"></i></div>
                <ul id="main-menu" class="sm pixelstrap sm-horizontal" style="margin-left: -18%;background-color: #FF324D;">
                  <li class="d-flex justify-content-between d-lg-none d-block">
                    <a class="rounded-circle" role="button" id="dropdownUser" data-toggle="dropdown"
                      aria-haspopup="true" aria-expanded="false">
                      <span *ngIf="checkTrue">
                        <img alt="avatar" src="assets/images/avatar.jfif" alt="avatar" width="22" height="22"
                          class="rounded-circle">
                      </span>
                      <span *ngIf="checkFalse">
                        <img alt="avatar" *ngIf="(loginUser?.avatarPath != null)"
                          [src]="globalService.imgUrl(loginUser?.avatarPath,loginUser?.avatar,60,60)" alt="avatar"
                          width="22" height="22" class="rounded-circle">
                        <img alt="avatar" *ngIf="loginUser?.avatarPath == null" src="./assets/images/avatar.jfif"
                          alt="avatar" width="22" height="22" class="rounded-circle">
                        &nbsp;&nbsp;{{currentUser?.firstName}}
                      </span>
                    </a>
                    <div class="mobile-back">

                      <i class="fa fa-close ps-2" aria-hidden="true" style="color: white;"></i>
                    </div>
                  </li>
                  <li *ngIf="checkTrue" class="d-lg-none d-block">
                    <a class="dark-menu-item" (click)="openLoginpage();hideClass()" routerLinkActive="active" style="color: white;">
                      Login
                    </a>
                  </li>
                  <li><a class="dark-menu-item new_temp" style="color: white;font-size: 12px;" routerLink="/{{domainURL}}/home" routerLinkActive="active"
                      (click)="hideClass()">Home</a></li>

                  <li><a class="dark-menu-item new_temp" style="color: white;font-size: 12px;" routerLink="/{{domainURL}}/deals" routerLinkActive="active"
                      (click)="hideClass()">Deals</a></li>
                  <li class="dropdown ">

                    <!-- <button class="dropbtn">Dropdown</button> -->
                    <a class="dark-menu-item v new_temp"  style="color: white;font-size: 12px;" routerLink="/{{domainURL}}/product" routerLinkActive="active"
                      (click)="hideClass()">Products <i class="fa fa-angle-down" aria-hidden="true"></i></a>
                    <div class="dropdown-menu new-design">
                      <div class="Design-changes">
                        <a class="dark-menu-item v" routerLink="/{{domainURL}}/product" routerLinkActive="active"
                          (click)="hideClass()"> All</a>
                        <a *ngFor="let item of categoryList;" routerLink="/{{domainURL}}/product" (click)="hideClass()"
                          [queryParams]="{catId: item.categoryId,brandId:''}" routerLinkActive="active">
                          {{item?.name}}
                        </a>
                      </div>
                    </div>

                  </li>
                  <li><a class="dark-menu-item new_temp"  style="color: white;font-size: 12px;" routerLink="/{{domainURL}}/about" routerLinkActive="active"
                      (click)="hideClass()">About Us</a></li>
                  <!-- <li><a class="dark-menu-item" href="#">QR Code</a></li> -->
                  <li><a class="dark-menu-item new_temp"  style="color: white;font-size: 12px;" routerLink="/{{domainURL}}/contact" routerLinkActive="active"
                      (click)="hideClass()">Contact Us</a></li>

                  <!-- <li *ngIf="checkTrue" class="d-lg-none d-block dark-menu-item">
                    <a class="dark-menu-item" (click)="openRegisterPage()" routerLinkActive="active">
                      Register
                    </a>
                  </li> -->
                  <li *ngIf="checkFalse" class="d-lg-none d-block">
                    <a class="dark-menu-item" routerLink="/{{domainURL}}/editProfile" routerLinkActive="active"
                      (click)="hideClass()">
                      Edit Profile
                    </a>
                  </li>
                  <!-- <li *ngIf="checkFalse" class="d-lg-none d-block">
                    <a class="dark-menu-item" routerLink="/{{shortURl}}/wishLists" routerLinkActive="active"
                      (click)="hideClass()">
                      Wishlist
                    </a>
                  </li>
                  <li *ngIf="checkFalse" class="d-lg-none d-block">
                    <a class="dark-menu-item" routerLink="/{{shortURl}}/orderHistory" routerLinkActive="active"
                      (click)="hideClass()">
                      Order History
                    </a>
                  </li> -->
                  <li *ngIf="checkFalse" class="d-lg-none d-block">
                    <a class="dark-menu-item" (click)="logout();hideClass()" routerLinkActive="active">
                      Sign Out
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
            <div class="icon-block mob_icons_not_show">
              <div class="input-block" id="cus_searchbar">
                <div class="input-box">
                  <!-- <form class="big-deal-form">
                    <div class="input-group ">
                      <input type="text" id="global-search" class="form-control" placeholder="Search a Product"
                        [(ngModel)]="searchTerm" autocomplete="off" [ngModelOptions]="{standalone: true}" (keyup)="searchProducts($event);getGlobelSearch($event);op.toggle($event)"> -->
                      <!-- <i class="fa fa-camera img_search d-none d-lg-block" ></i>
                      <i class="fa fa-microphone voice_search d-none d-lg-block"></i> -->
                      <!-- <span class="search"><i class="fa fa-search" (click)="searchProduct()"></i></span>
                    </div>
                  </form> -->
                  <form class="big-deal-form">
                    <div class="input-group ">
                      <input type="search" id="global-search" class="form-control" placeholder="Search a Product"
                        [(ngModel)]="searchTerm" autocomplete="off" [ngModelOptions]="{standalone: true}" (click)="searchProducts($event);getGlobelSearch($event);" (keyup)="searchProducts($event);getGlobelSearch($event);">
                      <!-- <i class="fa fa-camera img_search d-none d-lg-block" ></i>
                      <i class="fa fa-microphone voice_search d-none d-lg-block"></i> -->
                      <span class="search"><i class="fa fa-search" (click)="searchProduct()"></i></span>
                    </div>                    
                  </form>
                  <div class="resultbox" *ngIf="search_show">
                    <ul *ngFor="let item of searchResult" class="result_ul">
                      <li class="result_li"  style="cursor: pointer;" (click)="onRowSelect(item);">{{item.name}}</li>
                    </ul>
                  </div>   
                  <div class="resultbox" *ngIf="search_show1">
                    <ul class="result_ul">
                      <li class="result_li">No Records Found!</li>
                    </ul>                    
                  </div>
                </div>
              </div>
              <ul class="theme-color">
                <!-- <li class="mobile-cart item-count compare_sticky" data-tippy-content="Compare" data-tippy-placement="bottom">
                    <a href="compare.html">
                      <i class="fa fa-share text-white"></i>
                    </a>
                  
                  </li> -->
                <li title="Wish List" style="background-color: white;" *ngIf="checkFalse" class="mobile-cart item-count history_sticky"
                  data-tippy-content="Wishlist" data-tippy-placement="bottom">
                  <a routerLink="/{{domainURL}}/wishLists">
                    <svg xmlns="http://www.w3.org/2000/svg" style="fill: #FF324D;" width="24" height="24" viewBox="0 0 24 24">
                      <path
                        d="M12 4.248c-3.148-5.402-12-3.825-12 2.944 0 4.661 5.571 9.427 12 15.808 6.43-6.381 12-11.147 12-15.808 0-6.792-8.875-8.306-12-2.944z" />
                    </svg>
                    <div class="item-count-contain inverce" *ngIf="cartItemCount?.wishlist != 0">
                      {{cartItemCount?.wishlist}}
                    </div>

                  </a>
                </li>

                <li title="Generate Query" style="background-color: white;" *ngIf="checkFalse" class="mobile-cart item-count history_sticky"
                  data-tippy-content="Wishlist" data-tippy-placement="bottom">
                  <a routerLink="/{{domainURL}}/queryList">
                    <i class="ti-email" style="color:#FF324D"></i>
                    <div class="item-count-contain inverce" *ngIf="cartItemCount?.query != 0">
                      {{cartItemCount?.query}}
                    </div>

                  </a>
                </li>
                <li *ngIf="checkFalse" style="background-color: white;" title="Order History" class="mobile-cart item-count history_sticky"
                  data-tippy-content="Order History" data-tippy-placement="bottom">
                  <a routerLink="/{{domainURL}}/orderHistory">
                    <svg xmlns="http://www.w3.org/2000/svg" style="fill: #FF324D;" width="24" height="24" viewBox="0 0 24 24">
                      <path
                        d="M24 12c0 6.627-5.373 12-12 12s-12-5.373-12-12h2c0 5.514 4.486 10 10 10s10-4.486 10-10-4.486-10-10-10c-2.777 0-5.287 1.141-7.099 2.977l2.061 2.061-6.962 1.354 1.305-7.013 2.179 2.18c2.172-2.196 5.182-3.559 8.516-3.559 6.627 0 12 5.373 12 12zm-13-6v8h7v-2h-5v-6h-2z" />
                    </svg>
                  </a>

                </li>

                <!-- <li class="mobile-cart item-count" title="Track Order" data-tippy-content="Track Order"
                  data-tippy-placement="bottom">
                  <a href="#">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                      <path
                        d="M12 0c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z" />
                    </svg>
                  </a>
                </li> -->
                <li title="Add To Cart" style="background-color: white;" *ngIf="checkFalse" class="mobile-cart item-count history_sticky"
                  data-tippy-content="Add to Cart" data-tippy-placement="bottom">
                  <a routerLink="/{{domainURL}}/cart">
                    <svg xmlns="http://www.w3.org/2000/svg" style="fill: #FF324D;" width="24" height="24" viewBox="0 0 24 24">
                      <path
                        d="M10 19.5c0 .829-.672 1.5-1.5 1.5s-1.5-.671-1.5-1.5c0-.828.672-1.5 1.5-1.5s1.5.672 1.5 1.5zm3.5-1.5c-.828 0-1.5.671-1.5 1.5s.672 1.5 1.5 1.5 1.5-.671 1.5-1.5c0-.828-.672-1.5-1.5-1.5zm1.336-5l1.977-7h-16.813l2.938 7h11.898zm4.969-10l-3.432 12h-12.597l.839 2h13.239l3.474-12h1.929l.743-2h-4.195z" />
                    </svg>
                    <div class="item-count-contain inverce" *ngIf="cartItemCount?.cart != 0">
                      {{cartItemCount?.cart}}
                    </div>
                  </a>
                </li>
                <!-- <li *ngIf="checkFalse" style="background-color: white;" title="Edit profile" class="mobile-cart item-count history_sticky"
                  data-tippy-content="Edit profile" data-tippy-placement="bottom">
                  <a routerLink="/{{shortURl}}/editProfile" routerLinkActive="active">
                    <svg style="fill: #FF324D;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M402.6 83.2l90.2 90.2c3.8 3.8 3.8 10 0 13.8L274.4 405.6l-92.8 10.3c-12.4 1.4-22.9-9.1-21.5-21.5l10.3-92.8L388.8 83.2c3.8-3.8 10-3.8 13.8 0zm162-22.9l-48.8-48.8c-15.2-15.2-39.9-15.2-55.2 0l-35.4 35.4c-3.8 3.8-3.8 10 0 13.8l90.2 90.2c3.8 3.8 10 3.8 13.8 0l35.4-35.4c15.2-15.3 15.2-40 0-55.2zM384 346.2V448H64V128h229.8c3.2 0 6.2-1.3 8.5-3.5l40-40c7.6-7.6 2.2-20.5-8.5-20.5H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V306.2c0-10.7-12.9-16-20.5-8.5l-40 40c-2.2 2.3-3.5 5.3-3.5 8.5z"/></svg>
                  </a>

                </li>
                <li *ngIf="checkFalse" style="background-color: white;" title="Sign out" class="mobile-cart item-count history_sticky"
                  data-tippy-content="Signout" data-tippy-placement="bottom">
                  <a (click)="logout();hideClass()" routerLinkActive="active">
                    <svg style="fill: #FF324D;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M400 54.1c63 45 104 118.6 104 201.9 0 136.8-110.8 247.7-247.5 248C120 504.3 8.2 393 8 256.4 7.9 173.1 48.9 99.3 111.8 54.2c11.7-8.3 28-4.8 35 7.7L162.6 90c5.9 10.5 3.1 23.8-6.6 31-41.5 30.8-68 79.6-68 134.9-.1 92.3 74.5 168.1 168 168.1 91.6 0 168.6-74.2 168-169.1-.3-51.8-24.7-101.8-68.1-134-9.7-7.2-12.4-20.5-6.5-30.9l15.8-28.1c7-12.4 23.2-16.1 34.8-7.8zM296 264V24c0-13.3-10.7-24-24-24h-32c-13.3 0-24 10.7-24 24v240c0 13.3 10.7 24 24 24h32c13.3 0 24-10.7 24-24z"/></svg>
                  </a>

                </li> -->
              </ul>
              <div class="top-header-right d-none d-lg-block">
            <ul>
              <!-- <li>
                  <a href="#"><i class="fa fa-share"></i> Compare</a>
                </li> -->
              <!--<li onclick="openWishlist()">
                <a href="javascript:void(0)"><i class="fa fa-heart"></i> Wishlist</a>
              </li>
              <li>
                <a href="order-history.html"><i class="fa fa-history"></i> order history</a>
              </li> -->

              <!-- <li onclick="openAccount()">
                <a href="javascript:void(0)"><img class="rounded-circle" src="assets/images/avatar-s-3.jpg" alt="avatar" width="22" height="22"> &nbsp;&nbsp;Hello Kanika</a>
              </li> -->
              <li class="dropdown ml-2" id="user_profile">

                <a class="rounded-circle" role="button" id="dropdownUser" data-toggle="dropdown" aria-haspopup="true"
                  aria-expanded="false">
                  <span *ngIf="checkTrue" class="text-white">
                    <img alt="avatar" (click)="openLoginpage()" src="assets/images/avatar.jfif" alt="avatar" width="35" height="35"
                      class="rounded-circle" style="margin-left: -50%;"> &nbsp;&nbsp;<span class="sub-arrow"></span>
                  </span>
                  <span *ngIf="checkFalse" class="text-white">
                    <img alt="avatar" *ngIf="(loginUser?.avatarPath != null)"
                      [src]="globalService.imgUrl(loginUser?.avatarPath,loginUser?.avatar,60,60)" alt="avatar"
                      width="35" height="35" class="rounded-circle" style="margin-left: -43%; margin-top: 6%;">
                    <img alt="avatar" *ngIf="loginUser?.avatarPath == null" src="./assets/images/avatar.jfif"
                      alt="avatar" width="35" height="35" class="rounded-circle" style="margin-left: -43%; margin-top: 6%;">
                    &nbsp;&nbsp;<span class="sub-arrow"></span>
                  </span>
                </a>

                <div class="dropdown-menu pb-2" aria-labelledby="dropdownUser">

                  <div class="">
                    <ul class="list-unstyled">

                      <!-- <li *ngIf="checkTrue">
                        <a class="dropdown-item" (click)="openLoginpage()" routerLinkActive="active">
                          <span class="mr-2">

                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24"
                              fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round" class="feather feather-user">
                              <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                              <circle cx="12" cy="7" r="4"></circle>
                            </svg>
                          </span>Login
                        </a>
                      </li> -->
                      <!-- <li *ngIf="checkTrue">
                        <a class="dropdown-item" (click)="openRegisterPage()" routerLinkActive="active">
                          <span class="mr-2">

                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24"
                              fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round" class="feather feather-user">
                              <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                              <circle cx="12" cy="7" r="4"></circle>
                            </svg>
                          </span>Register
                        </a>
                      </li> -->
                      <li *ngIf="checkFalse">
                        <a class="dropdown-item" routerLink="/{{domainURL}}/editProfile" style="margin-left: -13%;">
                          <span class="mr-2">

                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24"
                              fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round" class="feather feather-user">
                              <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                              <circle cx="12" cy="7" r="4"></circle>
                            </svg>
                          </span>Edit Profile
                        </a>
                      </li>
                      <!-- <li *ngIf="checkFalse">
                        <a class="dropdown-item" routerLink="/{{domainURL}}/wishLists">
                          <span class="mr-2">

                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24">
                              <path
                                d="M6.28 3c3.236.001 4.973 3.491 5.72 5.031.75-1.547 2.469-5.021 5.726-5.021 2.058 0 4.274 1.309 4.274 4.182 0 3.442-4.744 7.851-10 13-5.258-5.151-10-9.559-10-13 0-2.676 1.965-4.193 4.28-4.192zm.001-2c-3.183 0-6.281 2.187-6.281 6.192 0 4.661 5.57 9.427 12 15.808 6.43-6.381 12-11.147 12-15.808 0-4.011-3.097-6.182-6.274-6.182-2.204 0-4.446 1.042-5.726 3.238-1.285-2.206-3.522-3.248-5.719-3.248z" />
                            </svg>
                          </span>Wishlist
                        </a>
                      </li> -->
                      <!-- <li *ngIf="checkFalse">
                        <a class="dropdown-item" routerLink="/{{domainURL}}/orderHistory">
                          <span class="mr-2">

                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24">
                              <path
                                d="M24 12c0 6.627-5.373 12-12 12s-12-5.373-12-12h2c0 5.514 4.486 10 10 10s10-4.486 10-10-4.486-10-10-10c-2.777 0-5.287 1.141-7.099 2.977l2.061 2.061-6.962 1.354 1.305-7.013 2.179 2.18c2.172-2.196 5.182-3.559 8.516-3.559 6.627 0 12 5.373 12 12zm-13-6v8h7v-2h-5v-6h-2z" />
                            </svg>
                          </span>Order History
                        </a>
                      </li> -->
                      <!-- <li *ngIf="checkFalse">
                        <a class="dropdown-item" routerLink="/{{shortURl?.shortUrl}}/404">
                          <span class="mr-2">

                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24">
                              <path
                                d="M12 2c3.196 0 6 2.618 6 5.602 0 3.093-2.493 7.132-6 12.661-3.507-5.529-6-9.568-6-12.661 0-2.984 2.804-5.602 6-5.602m0-2c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z" />
                            </svg>
                          </span>Order Tracking
                        </a>
                      </li>
                      <li *ngIf="checkFalse">
                        <a class="dropdown-item" routerLink="/{{shortURl?.shortUrl}}/404" onclick="mySetting()">
                          <span class="mr-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24"
                              fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round" class="feather feather-settings">
                              <circle cx="12" cy="12" r="3"></circle>
                              <path
                                d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z">
                              </path>
                            </svg></span>Settings
                        </a>
                      </li> -->
                    </ul>
                  </div>
                  <div *ngIf="checkFalse">
                    <!-- <div class="dropdown-divider"></div> -->
                    <ul class="list-unstyled">
                      <li>
                        <a class="dropdown-item border-0" (click)="logout()" style="margin-left: -13%;">
                          <span class="mr-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24"
                              fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round" class="feather feather-power">
                              <path d="M18.36 6.64a9 9 0 1 1-12.73 0"></path>
                              <line x1="12" y1="2" x2="12" y2="12"></line>
                            </svg></span>Sign Out
                        </a>
                      </li>

                    </ul>
                  </div>
                </div>
              </li>
            </ul>
          </div>  
            </div>
            
            <div class="toggle-nav" (click)="sidenav()"><i class="fa fa-bars sidebar-bar" style="color: white;"></i></div>
          </div>
        </div>
      </div>
    </div>
    <div class="searchbar-input">
      <div class="input-group">
        <span class="input-group-text"><svg version="1.1" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="28.931px" height="28.932px"
            viewBox="0 0 28.931 28.932" style="enable-background:new 0 0 28.931 28.932;" xml:space="preserve">
            <g>
              <path
                d="M28.344,25.518l-6.114-6.115c1.486-2.067,2.303-4.537,2.303-7.137c0-3.275-1.275-6.355-3.594-8.672C18.625,1.278,15.543,0,12.266,0C8.99,0,5.909,1.275,3.593,3.594C1.277,5.909,0.001,8.99,0.001,12.266c0,3.276,1.275,6.356,3.592,8.674c2.316,2.316,5.396,3.594,8.673,3.594c2.599,0,5.067-0.813,7.136-2.303l6.114,6.115c0.392,0.391,0.902,0.586,1.414,0.586c0.513,0,1.024-0.195,1.414-0.586C29.125,27.564,29.125,26.299,28.344,25.518z M6.422,18.111c-1.562-1.562-2.421-3.639-2.421-5.846S4.86,7.983,6.422,6.421c1.561-1.562,3.636-2.422,5.844-2.422s4.284,0.86,5.845,2.422c1.562,1.562,2.422,3.638,2.422,5.845s-0.859,4.283-2.422,5.846c-1.562,1.562-3.636,2.42-5.845,2.42S7.981,19.672,6.422,18.111z" />
            </g>
          </svg></span>
        <input type="text" class="form-control" placeholder="search your product">
        <span class="input-group-text close-searchbar"><svg viewBox="0 0 329.26933 329"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0" />
          </svg></span>
      </div> 
    </div>
  </div>
</header>
<!-- manufacturer template djay -->

<header id="stickyheader" *ngIf="templateId == 4" style="top: 0;position: sticky;z-index: 999;">
<!-- <header class="sticky_strip">
  <div class="container">
    <nav class="bottom-nav">
      <div class="bottom-nav-item">
        <a routerLink="/{{domainURL}}/home" class="bottom-nav-link">
          <i class="fa fa-home"></i>
          <span>Home</span>
        </a>
      </div>
      <div class="bottom-nav-item">
        <a (click)="getWishListData()" class="bottom-nav-link">
          <i class="fa fa-heart"></i>
          <span>Wishlist</span>
        </a>
      </div>
      <div class="bottom-nav-item">
        <div class="item-count-contain inverce" *ngIf="cartItemCount?.cart != 0" style="background-color: orange;width: 46%; border-radius: 15px; margin-left: 64%;">
          {{cartItemCount?.cart}}
        </div>
        <a (click)="getCartData()" class="bottom-nav-link">
          <i class="fa fa-shopping-cart"></i>
          <span>Cart</span>
          
        </a>
      </div>
      <div class="bottom-nav-item">
        <a (click)="getEditProfleData()" class="bottom-nav-link">
          <i class="ti-email" style="color:#fff"></i>
          <span>Generate Query</span>
        </a>
      </div>

      <div class="bottom-nav-item">
        <a (click)="getOrderHistoryData()" class="bottom-nav-link">
          <i class="fa fa-refresh"></i>
          <span>Order History</span>
        </a>
      </div>
    </nav>
  </div>
</header> -->

<div class="searchbar-main header7" style="background-color: #1F1F1F;">
  <div class="custom-container">
    <div class="row">
      <div class="col-12">
        <div class="header-contain">
          <div class="logo-block">
            <div class="brand-logo">
              <a>
                <img routerLink="/{{domainURL}}/home" [src]="this.headAllLogo" class="img-fluid" alt="logo">
              </a>
            </div>
          </div>
          <div class="menu-block">
            <nav id="main-nav" class="main-nav-oo">
              <div class="toggle-nav" (click)="sidenav()"><i class="fa fa-bars sidebar-bar"></i></div>
              <ul id="main-menu" class="sm pixelstrap sm-horizontal" style="margin-left: 0%;background-color: #1F1F1F;">
                <li class="d-flex justify-content-between d-lg-none d-block">
                  <a class="rounded-circle" role="button" id="dropdownUser" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">
                    <span *ngIf="checkTrue">
                      <img alt="avatar" src="assets/images/avatar.jfif" alt="avatar" width="22" height="22"
                        class="rounded-circle">
                    </span>
                    <span *ngIf="checkFalse">
                      <img alt="avatar" *ngIf="(loginUser?.avatarPath != null)"
                        [src]="globalService.imgUrl(loginUser?.avatarPath,loginUser?.avatar,60,60)" alt="avatar"
                        width="22" height="22" class="rounded-circle">
                      <img alt="avatar" *ngIf="loginUser?.avatarPath == null" src="./assets/images/avatar.jfif"
                        alt="avatar" width="22" height="22" class="rounded-circle">
                      &nbsp;&nbsp;{{currentUser?.firstName}}
                    </span>
                  </a>
                  <div class="mobile-back">

                    <i class="fa fa-close ps-2" aria-hidden="true" style="color: white;"></i>
                  </div>
                </li>
                <li *ngIf="checkTrue" class="d-lg-none d-block">
                  <a class="dark-menu-item" (click)="openLoginpage();hideClass()" routerLinkActive="active" style="color: white;">
                    Login
                  </a>
                </li>
                <li><a class="dark-menu-item new_temp" style="color: white;font-size: 12px;" routerLink="/{{domainURL}}/home" routerLinkActive="active"
                    (click)="hideClass()">Home</a></li>
                    <li><a class="dark-menu-item new_temp" (click)="scrollToTop()" style="color: white;font-size: 12px;" routerLink="/{{domainURL}}/about" routerLinkActive="active"
                      (click)="hideClass()">About Us</a></li>
                <li class="dropdown ">
                  <a class="dark-menu-item v new_temp" (click)="scrollToTop()"  style="color: white;font-size: 12px;" routerLink="/{{domainURL}}/product" routerLinkActive="active"
                    (click)="hideClass()">Portfolio</a>
                </li>
                <li><a class="dark-menu-item new_temp" (click)="scrollToTop()" style="color: white;font-size: 12px;" routerLink="/{{domainURL}}/contact" routerLinkActive="active"
                    (click)="hideClass()">Contact Us</a></li>
                <li *ngIf="checkFalse" class="d-lg-none d-block">
                  <a class="dark-menu-item" routerLink="/{{domainURL}}/editProfile" routerLinkActive="active"
                    (click)="hideClass()">
                    <i class="fa fa-edit" style="color:#C9F31D"></i> <span style="color: white;"> Edit Profile </span>
                  </a>
                </li>
                <li *ngIf="checkFalse" class="dark-menu-item d-lg-none d-block">
                  <a class="dark-menu-item" routerLink="/{{domainURL}}/wishLists" routerLinkActive="active"
                  (click)="hideClass()">
                  <i class="fa fa-heart" style="color:#C9F31D"></i> <span style="color: white;"> Wishlist</span>
                  </a>
                </li>
                <li *ngIf="checkFalse" class="dark-menu-item d-lg-none d-block">
                <a (click)="hideClass()" routerLink="/{{domainURL}}/cart" class="bottom-nav-link" routerLinkActive="active">
                  <i class="fa fa-shopping-cart" style="color:#C9F31D"></i> <span style="color: white;"> Cart </span>
                </a>
                </li>
                <li *ngIf="checkFalse" class="dark-menu-item d-lg-none d-block">
                  <a (click)="hideClass()" class="bottom-nav-link" routerLink="/{{domainURL}}/queryList">
                    <i class="ti-email" style="color:#C9F31D"></i> <span style="color: white;"> Generate Query </span>
                  </a>
                </li>
                <li *ngIf="checkFalse" class="dark-menu-item d-lg-none d-block">
                  <a (click)="hideClass()" class="bottom-nav-link" routerLink="/{{domainURL}}/orderHistory">
                    <i class="fa fa-refresh" style="color:#C9F31D"></i> <span style="color: white;"> Order History </span>
                  </a>
                </li>
                <li *ngIf="checkFalse" class="d-lg-none d-block">
                  <a class="dark-menu-item"  (click)="logout();hideClass()" routerLinkActive="active">
                    <i class="fa fa-sign-out" style="color:#C9F31D"></i> <span style="color: white;"> Sign Out </span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
          <div class="icon-block mob_icons_not_show">
            
            <ul class="theme-color">
              <li title="Wish List" style="background-color: black;" *ngIf="checkFalse" class="mobile-cart item-count history_sticky d-none d-md-flex"
                data-tippy-content="Wishlist" data-tippy-placement="bottom">
                <a routerLink="/{{domainURL}}/wishLists" (click)="scrollToTop()">
                  <svg xmlns="http://www.w3.org/2000/svg" style="fill: #C9F31D;" width="24" height="24" viewBox="0 0 24 24">
                    <path
                      d="M12 4.248c-3.148-5.402-12-3.825-12 2.944 0 4.661 5.571 9.427 12 15.808 6.43-6.381 12-11.147 12-15.808 0-6.792-8.875-8.306-12-2.944z" />
                  </svg>
                  <div class="item-count-contain inverce d-none d-md-flex" *ngIf="cartItemCount?.wishlist != 0">
                    {{cartItemCount?.wishlist}}
                  </div>

                </a>
              </li>

              <li title="Generate Query" style="background-color: black;" *ngIf="checkFalse" class="mobile-cart item-count history_sticky d-none d-md-flex"
                data-tippy-content="Wishlist" data-tippy-placement="bottom">
                <a routerLink="/{{domainURL}}/queryList" (click)="scrollToTop()">
                  <i class="ti-email" style="color:#C9F31D"></i>
                  <div class="item-count-contain inverce d-none d-md-flex" *ngIf="cartItemCount?.query != 0">
                    {{cartItemCount?.query}}
                  </div>

                </a>
              </li>
              <li *ngIf="checkFalse" style="background-color: black;" title="Order History" class="mobile-cart item-count history_sticky d-none d-md-flex"
                data-tippy-content="Order History" data-tippy-placement="bottom">
                <a routerLink="/{{domainURL}}/orderHistory" (click)="scrollToTop()">
                  <svg xmlns="http://www.w3.org/2000/svg" style="fill: #C9F31D;" width="24" height="24" viewBox="0 0 24 24">
                    <path
                      d="M24 12c0 6.627-5.373 12-12 12s-12-5.373-12-12h2c0 5.514 4.486 10 10 10s10-4.486 10-10-4.486-10-10-10c-2.777 0-5.287 1.141-7.099 2.977l2.061 2.061-6.962 1.354 1.305-7.013 2.179 2.18c2.172-2.196 5.182-3.559 8.516-3.559 6.627 0 12 5.373 12 12zm-13-6v8h7v-2h-5v-6h-2z" />
                  </svg>
                </a>

              </li>
              <li title="Add To Cart" style="background-color: black;" *ngIf="checkFalse" class="mobile-cart item-count history_sticky d-none d-md-flex"
                data-tippy-content="Add to Cart" data-tippy-placement="bottom">
                <a routerLink="/{{domainURL}}/cart" (click)="scrollToTop()">
                  <svg xmlns="http://www.w3.org/2000/svg" style="fill: #C9F31D;" width="24" height="24" viewBox="0 0 24 24">
                    <path
                      d="M10 19.5c0 .829-.672 1.5-1.5 1.5s-1.5-.671-1.5-1.5c0-.828.672-1.5 1.5-1.5s1.5.672 1.5 1.5zm3.5-1.5c-.828 0-1.5.671-1.5 1.5s.672 1.5 1.5 1.5 1.5-.671 1.5-1.5c0-.828-.672-1.5-1.5-1.5zm1.336-5l1.977-7h-16.813l2.938 7h11.898zm4.969-10l-3.432 12h-12.597l.839 2h13.239l3.474-12h1.929l.743-2h-4.195z" />
                  </svg>
                  <div class="item-count-contain inverce d-none d-md-flex" *ngIf="cartItemCount?.cart != 0" >
                    {{cartItemCount?.cart}}
                  </div>
                </a>
              </li>
            </ul>
            <div class="top-header-right d-none d-lg-block">
          <ul>
            <li class="dropdown ml-2" id="user_profile">

              <a class="rounded-circle" role="button" id="dropdownUser" data-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false">
                <span *ngIf="checkTrue" class="text-white">
                  <img alt="avatar" (click)="openLoginpage()" src="assets/images/avatar.jfif" alt="avatar" width="35" height="35"
                    class="rounded-circle"> &nbsp;&nbsp;<span class="sub-arrow"></span>
                </span>
                <span *ngIf="checkFalse" class="text-white">
                  <img alt="avatar" *ngIf="(loginUser?.avatarPath != null)"
                    [src]="globalService.imgUrl(loginUser?.avatarPath,loginUser?.avatar,60,60)" alt="avatar"
                    width="35" height="35" class="rounded-circle" style="margin-left: -43%; margin-top: 6%;">
                  <img alt="avatar" *ngIf="loginUser?.avatarPath == null" src="./assets/images/avatar.jfif"
                    alt="avatar" width="35" height="35" class="rounded-circle" style="margin-left: -43%; margin-top: 6%;">
                  &nbsp;&nbsp;<span class="sub-arrow"></span>
                </span>
              </a>

              <div class="dropdown-menu pb-2" aria-labelledby="dropdownUser">

                <div class="">
                  <ul class="list-unstyled">
                    <li *ngIf="checkFalse">
                      <a class="dropdown-item" routerLink="/{{domainURL}}/editProfile" style="margin-left: -13%;">
                        <span class="mr-2">

                          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24"
                            fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" class="feather feather-user">
                            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                            <circle cx="12" cy="7" r="4"></circle>
                          </svg>
                        </span>Edit Profile
                      </a>
                    </li>
                  </ul>
                </div>
                <div *ngIf="checkFalse">
                  <ul class="list-unstyled">
                    <li>
                      <a class="dropdown-item border-0" (click)="logout()" style="margin-left: -13%;">
                        <span class="mr-2">
                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24"
                            fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" class="feather feather-power">
                            <path d="M18.36 6.64a9 9 0 1 1-12.73 0"></path>
                            <line x1="12" y1="2" x2="12" y2="12"></line>
                          </svg></span>Sign Out
                      </a>
                    </li>

                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </div>  
          </div>
          
          <div class="toggle-nav" (click)="sidenav()"><i class="fa fa-bars sidebar-bar" style="color: white;"></i></div>
        </div>
      </div>
    </div>
  </div>
  <div class="searchbar-input">
    <div class="input-group">
      <span class="input-group-text"><svg version="1.1" xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="28.931px" height="28.932px"
          viewBox="0 0 28.931 28.932" style="enable-background:new 0 0 28.931 28.932;" xml:space="preserve">
          <g>
            <path
              d="M28.344,25.518l-6.114-6.115c1.486-2.067,2.303-4.537,2.303-7.137c0-3.275-1.275-6.355-3.594-8.672C18.625,1.278,15.543,0,12.266,0C8.99,0,5.909,1.275,3.593,3.594C1.277,5.909,0.001,8.99,0.001,12.266c0,3.276,1.275,6.356,3.592,8.674c2.316,2.316,5.396,3.594,8.673,3.594c2.599,0,5.067-0.813,7.136-2.303l6.114,6.115c0.392,0.391,0.902,0.586,1.414,0.586c0.513,0,1.024-0.195,1.414-0.586C29.125,27.564,29.125,26.299,28.344,25.518z M6.422,18.111c-1.562-1.562-2.421-3.639-2.421-5.846S4.86,7.983,6.422,6.421c1.561-1.562,3.636-2.422,5.844-2.422s4.284,0.86,5.845,2.422c1.562,1.562,2.422,3.638,2.422,5.845s-0.859,4.283-2.422,5.846c-1.562,1.562-3.636,2.42-5.845,2.42S7.981,19.672,6.422,18.111z" />
          </g>
        </svg></span>
      <input type="text" class="form-control" placeholder="search your product">
      <span class="input-group-text close-searchbar"><svg viewBox="0 0 329.26933 329"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0" />
        </svg></span>
    </div> 
  </div>
</div>
</header>
<!-- <section class="main-hero-area pt-150 pb-80 rel z-1 abhi" *ngIf="templateId == 4" style="top: 0;position: sticky;z-index: 999;">
  <header class="main-header menu-absolute" style="background-color: #1F1F1F;">
            <div class="header-upper">
                <div class="custom-container w-75">

                    <div class="header-inner rel d-flex align-items-center">
                      <div class="brand-logo">
                        <a>
                          <img style="width: 70px; max-width: 100%; margin-left: -50%;margin-top: -21%;" routerLink="/{{domainURL}}/home" [src]="this.headAllLogo" class="img-fluid" alt="logo">
                        </a>
                      </div>

                        <div class="nav-outer clearfix mx-auto">
                            <nav class="main-menu navbar-expand-lg">
                                <div class="navbar-header">
                                    <button type="button" class="navbar-toggle me-4" data-bs-toggle="collapse" data-bs-target=".navbar-collapse">
                                        <span class="icon-bar"></span>
                                        <span class="icon-bar"></span>
                                        <span class="icon-bar"></span>
                                    </button>
                                </div>

                                <div class="navbar-collapse collapse clearfix">
                                    <ul class="navigation clearfix" style="font-size: 18px;margin-top: -6%;margin-left: -9%;">
                                        <li class="dropdown"><a routerLink="/{{domainURL}}/home">Home</a>
                                            
                                        </li>
                                        <li><a routerLink="/{{domainURL}}/about" (click)="scrollToTop()">About Us</a></li>
                                        
                                        <li class="dropdown"><a routerLink="/{{domainURL}}/product" (click)="scrollToTop()">Portfolio</a>
                                      
                                        </li>
                                        <li><a routerLink="/{{domainURL}}/contact" (click)="scrollToTop()">Contact</a></li>
                                    </ul>
                                </div>

                            </nav>
                            
                        </div>
                        
                        
                        <div class="menu-btns">
                          <div class="menu-sidebar">
                              <button (click)="toggleSidebar()">
                                  <img src="assets/images/sidebar-tottler.svg" alt="Toggler">
                              </button>
                          </div>
                      </div>
                      <nav class="sidebar" [class.active]="sidebarActive">
                        <div class="title">
                          <h4 class="text-white"><a class="rounded-circle" role="button" id="dropdownUser" data-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">
                            <span *ngIf="checkTrue">
                              <img alt="avatar" src="assets/images/avatar.jfif" alt="avatar" width="22" height="22"
                                class="rounded-circle"> &nbsp;&nbsp;Hello Guest
                            </span>
                            <span *ngIf="checkFalse">
                              <img alt="avatar" *ngIf="(loginUser?.avatarPath != null)"
                                [src]="globalService.imgUrl(loginUser?.avatarPath,loginUser?.avatar,60,60)" alt="avatar"
                                width="35" height="35" class="rounded-circle">
                              <img alt="avatar" *ngIf="loginUser?.avatarPath == null" src="./assets/images/avatar.jfif"
                                alt="avatar" width="35" height="35" class="rounded-circle">
                              &nbsp;&nbsp;{{currentUser?.firstName}}
                            </span>
                          </a></h4>
                      </div><br><br>
                      
                        <div style="display: grid;gap: 25px;font-size: 20px;">
                          <li *ngIf="checkTrue">
                            <span style="cursor: pointer;" class="dark-menu-item hover" (click)="openLoginpage();hideClass();closes();scrollToTop()" routerLinkActive="active">
                              <i class="fa fa-user" style="color: #C9F31D;"></i>&nbsp;Login
                            </span>
                          </li>

                          <li *ngIf="checkTrue">
                            <span style="cursor: pointer;" class="dark-menu-item hover" (click)="openRegisterPage();closes();scrollToTop()" routerLinkActive="active">
                              <i class="fa fa-user-plus"  style="color: #C9F31D;"></i>&nbsp;Register
                            </span>
                          </li>

                          <li *ngIf="checkFalse">
                            <span class="dark-menu-item hover" routerLink="/{{domainURL}}/wishLists" routerLinkActive="active"
                              (click)="closes();scrollToTop()" style="cursor: pointer;">
                              <i class="fa fa-heart" style="color: #C9F31D;"></i>&nbsp;Wishlist
                          </span>
                          </li>
                          <li *ngIf="checkFalse">
                            <span class="dark-menu-item hover" routerLink="/{{domainURL}}/orderHistory" routerLinkActive="active"
                              (click)="closes();scrollToTop()" style="cursor: pointer;">
                              <svg xmlns="http://www.w3.org/2000/svg" style="fill: #C9F31D;" width="24" height="24" viewBox="0 0 24 24">
                                <path
                                  d="M24 12c0 6.627-5.373 12-12 12s-12-5.373-12-12h2c0 5.514 4.486 10 10 10s10-4.486 10-10-4.486-10-10-10c-2.777 0-5.287 1.141-7.099 2.977l2.061 2.061-6.962 1.354 1.305-7.013 2.179 2.18c2.172-2.196 5.182-3.559 8.516-3.559 6.627 0 12 5.373 12 12zm-13-6v8h7v-2h-5v-6h-2z" />
                              </svg>&nbsp;Order History
                          </span>
                          </li>
                          <li *ngIf="checkFalse">
                            <span class="dark-menu-item hover" routerLink="/{{domainURL}}/queryList" routerLinkActive="active"
                              (click)="closes();scrollToTop()" style="cursor: pointer;">
                              <i class="fa fa-envelope" style="color: #C9F31D;"></i>&nbsp;Query List
                          </span>
                          </li>
                          <li *ngIf="checkFalse">
                            <span class="dark-menu-item hover" routerLink="/{{domainURL}}/cart" routerLinkActive="active"
                              (click)="closes();scrollToTop()" style="cursor: pointer;">
                              <svg xmlns="http://www.w3.org/2000/svg" style="fill: #C9F31D;" width="25" height="25" viewBox="0 0 24 24">
                                <path
                                  d="M10 19.5c0 .829-.672 1.5-1.5 1.5s-1.5-.671-1.5-1.5c0-.828.672-1.5 1.5-1.5s1.5.672 1.5 1.5zm3.5-1.5c-.828 0-1.5.671-1.5 1.5s.672 1.5 1.5 1.5 1.5-.671 1.5-1.5c0-.828-.672-1.5-1.5-1.5zm1.336-5l1.977-7h-16.813l2.938 7h11.898zm4.969-10l-3.432 12h-12.597l.839 2h13.239l3.474-12h1.929l.743-2h-4.195z" />
                              </svg>&nbsp;Add to Cart
                          </span>
                          </li>
                          <li *ngIf="checkFalse">
                            <span class="dark-menu-item hover" routerLink="/{{domainURL}}/editProfile" routerLinkActive="active"
                              (click)="closes();scrollToTop()" style="cursor: pointer;">
                              <i class="fa fa-edit" style="color: #C9F31D;"></i>&nbsp;Edit Profile
                          </span>
                          </li>

                          <li *ngIf="checkFalse">
                            <span class="dark-menu-item hover" (click)="logout();closes()" style="cursor: pointer;" routerLinkActive="active">
                              <i class="fa fa-sign-out" style="color: #C9F31D;"></i>&nbsp;Sign Out
                            </span>
                          </li>
                      </div>
                      </nav>
                    </div>
                </div>
            </div>
     
        </header>
        
        
</section> -->

<p-dialog header="Login" [draggable]="false" [(visible)]="isLogin" class="loginScreen" [modal]="true">
  <login_modal (LoginSuccess)="loginSuccess($event)"></login_modal>
</p-dialog>
<!--header end-->
<!-- wishlistbar bar -->
<div id="wishlist_side" class="add_to_cart right ">
  <app-wishlist-detail></app-wishlist-detail>
</div>
<!-- wishlistbar bar end-->

<!-- Add to cart bar -->
<div id="cart_side" class="add_to_cart right ">
  <app-cart-detail [CartItemCount]="CartItemCount"></app-cart-detail>
</div>
<!-- Add to cart bar end-->


<p-sidebar [(visible)]="visibleSidebar1" [baseZIndex]="10000" (onHide)="close()">
  <div class="menu-block">
    <nav id="main-nav" class="main-nav-oo">
      <ul id="main-menu" class="sm pixelstrap">
        <li class="d-flex justify-content-between d-lg-none d-block">
          <a class="rounded-circle" role="button" id="dropdownUser" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            <span *ngIf="checkTrue">
              <img alt="avatar" src="assets/images/avatar.jfif" alt="avatar" width="22" height="22"
                class="rounded-circle"> &nbsp;&nbsp;Hello Guest
            </span>
            <span *ngIf="checkFalse">
              <img alt="avatar" *ngIf="(loginUser?.avatarPath != null)"
                [src]="globalService.imgUrl(loginUser?.avatarPath,loginUser?.avatar,60,60)" alt="avatar"
                width="22" height="22" class="rounded-circle">
              <img alt="avatar" *ngIf="loginUser?.avatarPath == null" src="./assets/images/avatar.jfif"
                alt="avatar" width="22" height="22" class="rounded-circle">
              &nbsp;&nbsp;{{currentUser?.firstName}}
            </span>
          </a>
          
        </li>
        <li *ngIf="checkTrue" class="d-lg-none d-block">
          <a class="dark-menu-item" (click)="close()" routerLinkActive="active">
            Login
          </a>
        </li>
        <li><a class="dark-menu-item" routerLink="/{{domainURL}}/home" routerLinkActive="active"
            (click)="close()">Home</a></li>
            <li *ngIf="checkFalse" class="d-lg-none d-block">
              <a class="dark-menu-item" routerLink="/{{domainURL}}/wishLists" routerLinkActive="active"
                (click)="close()">
                Wishlist
              </a>
            </li>
            <li *ngIf="checkFalse" class="d-lg-none d-block">
              <a class="dark-menu-item" routerLink="/{{domainURL}}/queryList" routerLinkActive="active"
                (click)="close()">
              Query List
              </a>
            </li>
            <li *ngIf="checkFalse" class="d-lg-none d-block">
              <a class="dark-menu-item" routerLink="/{{domainURL}}/orderHistory" routerLinkActive="active"
                (click)="close()">
                Order History
              </a>
            </li>   
        <li class="dropdown ">

          <!-- <button class="dropbtn">Dropdown</button> -->
          <a class="dark-menu-item v" routerLink="/{{domainURL}}/product" routerLinkActive="active"
            (click)="close()">Products</a>
          <div class="dropdown-menu">
            <div class="">
              <a class="dark-menu-item v" routerLink="/{{domainURL}}/product" routerLinkActive="active"
                (click)="close()"> All</a>
              <a *ngFor="let item of categoryList;" routerLink="/{{domainURL}}/product" (click)="close()"
                [queryParams]="{catId: item.categoryId,brandId:''}" routerLinkActive="active">
                {{item?.name}}
              </a>
            </div>
          </div>

        </li>
        <li><a class="dark-menu-item" routerLink="/{{domainURL}}/about" routerLinkActive="active"
            (click)="close()">About Us</a></li>
        <!-- <li><a class="dark-menu-item" href="#">QR Code</a></li> -->
        <li><a class="dark-menu-item" routerLink="/{{domainURL}}/contact" routerLinkActive="active"
            (click)="close()">Contact Us</a></li>

        <!-- <li *ngIf="checkTrue" class="d-lg-none d-block dark-menu-item">
          <a class="dark-menu-item" (click)="openRegisterPage()" routerLinkActive="active">
            Register
          </a>
        </li> -->
        <li *ngIf="checkFalse" class="d-lg-none d-block">
          <a class="dark-menu-item" routerLink="/{{domainURL}}/editProfile" routerLinkActive="active"
            (click)="close()">
            Edit Profile
          </a>
        </li>
        <li *ngIf="checkFalse" class="d-lg-none d-block">
          <a class="dark-menu-item" (click)="logout();close()" routerLinkActive="active">
            Sign Out
          </a>
        </li>
      </ul>
    </nav>
  </div>
</p-sidebar>
<!-- For overrelay of list in search  -->
<p-overlayPanel #op>

  <ng-template pTemplate >
      <ul style="padding: 3px;width: 280px;" *ngFor="let item of searchResult">
          <li  style="cursor: pointer;" (click)="onRowSelect(item);op.hide($event)">{{item.name}}
          </li>
      </ul>
      <div class="row" *ngIf="!searchResult.length">
        No Records Found!
      </div>
  </ng-template>

</p-overlayPanel>