import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable,throwError } from 'rxjs';
import { catchError } from 'rxjs/internal/operators';
import { Router} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from '../appServices/authentication.service';
import {GlobalService} from '../appServices/globalService'

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService, private router: Router,
    private globalService:GlobalService,
    private toastr: ToastrService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let currentUser = this.authenticationService.currentUserValue;
    if (currentUser && currentUser.userInfo) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.globalService.getUserInfo().token??null}`,
          short_url : "margvendor3",
          customer_id : `${currentUser.userInfo.id}`
        }
      });
    }
      return next.handle(request).pipe(catchError((err:any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            this.toastr.error(err.error.message);
            this.authenticationService.logout();
            this.router.navigate(['login'])
          }else if (err.status === 400) {
             this.toastr.error(err.error.message);
          } else if(err.status==500){
            this.toastr.error("Something went wrong");
          }
          else{
            return throwError(err)
          }
        }
        return new Observable<HttpEvent<any>>();
      }));
  }
}
