import { Component, OnInit,Input } from '@angular/core';
import {ApiintegrationService} from '../../appServices/apiintegration.service';
import {ConstantsService} from '../../../app/constants.service';
import { GlobalService } from '../../appServices/globalService';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  footerData:any;
  timing: any = [];
  socialArray: any[] = [];
  displayTiming:boolean =false;
  imageUrls:string;
  newCompany:any;
  companyLogo:any;
  companyPath:string;
  companyImage:string;
  public vCode:any;
  isLogin: boolean = false;
  displayOpenScreen:string ='';
  days: any = [];
  sunday = 'sunday';
  monday = 'monday';
  tuesday = 'Tuesday';
  wednesday = 'Wednesday';
  thursday = 'Thursday';
  friday = 'Friday';
  saterday = 'Saturday';
  todaytimeing:any;
  errorDataContact: any;
  @Input('TemplateId') templateId;
  day: string;
  footerAllLogo: any;
  officeOpen: boolean = false;
  domainURL: any = this.globalService.domainUrl;
  
  constructor(private apiService:ApiintegrationService, private fb: FormBuilder,private router: Router, public globalService:GlobalService, public constantsService:ConstantsService) { 
    this.imageUrls = this.constantsService.imageUrl;
    this.globalService.footerLoginUpdate$.subscribe(res=>{
      this.isLogin = false;
    });
  }
  shortURl:any;
  urlShort:any;
  
  ngOnInit(): void {
    // this.templateId = this.globalService.userInfo.templateId;
    setTimeout(() => {
      this.shortURl = this.globalService.shortUrl;
      let url = window.location.pathname.split('/');
      this.urlShort = url[1];
      this.getFooterData();
    }, 500);
    
   // this.vCode = this.vcodeFunction();
  }

  scrollToTop() {
    document.body.scrollTop = 0;
  }

  abc:any

  getFooterData(){
    var today = (new Date().getDay())
    this.shortURl = this.globalService.shortUrl;
    this.apiService.homePage(this.shortURl).subscribe((res:any)=>{
       this.footerData = res.data;
       this.newCompany = this.footerData.logo.split('//');
       this.companyPath = this.newCompany[0];
       this.companyImage = this.newCompany[1];
       this.timing = res.data.timing[0]??[];
       this.socialArray = res.data.socials ?? [];
       this.companyLogo = this.imageUrls+'/'+this.footerData.vCode+ '?width=100&height=100&path=' + `${ this.newCompany[0]}`+ '/' + '&name=' + `${this.newCompany[1]}`;
       this.footerAllLogo = `https://margmartstorage.blob.core.windows.net/vendor-${this.footerData.vendorId}/${this.newCompany[0]}/${this.newCompany[1]}`;
       if(today == 0){
        this.days = this.sunday;
        this.day = 'Sunday';
        if(this.timing.sunday == 0){
         this.officeOpen = false;
        }else{
         this.officeOpen = true;
        }
     }else if(today == 1){
       this.days = this.monday;
       this.day = 'Monday';
       if(this.timing.monday == 0){
         this.officeOpen = false;
        }else{
         this.officeOpen = true;
        }
     }else if(today == 2){
       this.days = this.tuesday;
       this.day = 'Tuesday';
       if(this.timing.tuesday == 0){
         this.officeOpen = false;
        }else{
         this.officeOpen = true;
        }
     }else if(today == 3){
       this.days = this.wednesday;
       this.day = 'Wednesday';
       if(this.timing.wednesday == 0){
         this.officeOpen = false;
        }else{
         this.officeOpen = true;
        }
     }else if(today == 4){
       this.days = this.thursday;
       this.day = 'Thursday';
       if(this.timing.thursday == 0){
         this.officeOpen = false;
        }else{
         this.officeOpen = true;
        }
     }else if(today == 5){
       this.days = this.friday;
       this.day = 'Friday';
       if(this.timing.friday == 0){
         this.officeOpen = false;
        }else{
         this.officeOpen = true;
        }
     }else{
       this.days = this.saterday;
       this.day = 'Saterday';
       if(this.timing.saterday == 0){
         this.officeOpen = false;
        }else{
         this.officeOpen = true;
        }
     }
    },(error)=>{
      this.errorDataContact = error.error.data;
  });
  }

  loginSuccess(event) {
    this.isLogin = false;
    if (this.displayOpenScreen == "Edit Profile"){
    this.getEditProfleData();
    }
    else if (this.displayOpenScreen == "Order History"){
     this.getOrderHistoryData();
    }
    else{
      this.isLogin = false;
    }

  }

  onTop(){
    // window.scroll(0, 0);
    document.body.scrollTop = 0;
  }
  //Fpr Login Popup Close .
  public hidePopup = (event) => {
    this.isLogin = event;
  }

  getEditProfleData(){
    if(this.globalService.getUserInfo().token){
      this.router.navigateByUrl(`/${this.domainURL}/editProfile`);
      // window.scroll(0, 0);
      document.body.scrollTop = 0;
    }
    else{
      this.displayOpenScreen = "Edit Profile";
      this.isLogin = true;
    }
  }

  getOrderHistoryData(){
    if(this.globalService.getUserInfo().token){
      this.router.navigateByUrl(`/${this.domainURL}/orderHistory`);
      // window.scroll(0, 0);
      document.body.scrollTop = 0;
    }
    else{
      this.displayOpenScreen = "Order History";
      this.isLogin = true;
    } 
  }
  termsCondition(){
    this.router.navigateByUrl(`/${this.domainURL}/termsCondition`);
      // window.scroll(0, 0);
      document.body.scrollTop = 0;
  }
  about(){
    this.router.navigateByUrl(`/${this.domainURL}/about`);
      // window.scroll(0, 0);
      document.body.scrollTop = 0;
  }
}
