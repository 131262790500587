<p-dialog header="Verify OTP" [(visible)]="displayOTPB" [modal]="false" appendTo="body"
    [style]="{width: '30vw'}" [baseZIndex]="10000" [closable]="false" [draggable]="false" [resizable]="false">
    <!-- [contentStyle]="{'background-color': '#1f1f1f'}" -->
    <div class="container">
        <form [formGroup]="OTPForm">

            <div class="row">
                <div class="col-md-8 form-group">
                    <input type="text" onkeydown="javascript: return event.keyCode == 69 ? false : true" id="phoneNoumber" [readOnly]="edit" placeholder="Change register phoness"  
                    [ngClass]="{ 'is-invalid': isOTPsubmit && O.phone.errors}"
                    formControlName="phone" class="form-control">
                </div>
                <div class="col-md-4 form-group">
                    <i class="fa fa-edit" *ngIf="edit && resendOtplLink" (click)="onEditPhone();"></i>
                    <button type="button" *ngIf="!edit && resendOtplLink" (click)="changePhoneNumber();" class="form-control btn btn-primary">Submit</button>
                </div>
            </div>
            <p style="color: #bc931f;
            padding: 5px;" *ngIf="!resendOtplLink">
               Expire Time: OTP will be expire after <b>{{display}}</b> minutes
            </p>
            <div class="row">
                <div class="col-md-3 col-3 form-group">
                    <input id="1" (keyup)="focusNext($event)" formControlName="val1" maxlength="1" type="text" (keypress)="numericOnly($event)" class="form-control" placeholder="*"
                    [ngClass]="{ 'is-invalid': isOTPsubmit && O.val1.errors }">

                </div>
                <div class="col-md-3 col-3 form-group">
                    <input id="2" (keyup)="focusNext($event)" formControlName="val2" maxlength="1" type="text" (keypress)="numericOnly($event)" class="form-control" placeholder="*"
                    [ngClass]="{ 'is-invalid': isOTPsubmit && O.val2.errors }">

                </div>
                <div class="col-md-3 col-3 form-group">
                    <input id="3" (keyup)="focusNext($event)" formControlName="val3" maxlength="1" type="text" (keypress)="numericOnly($event)" class="form-control" placeholder="*"
                    [ngClass]="{ 'is-invalid': isOTPsubmit && O.val3.errors }">

                </div>
                <div class="col-md-3 col-3 form-group">
                    <input id="4" (keyup)="focusNext($event)" formControlName="val4" maxlength="1" type="text" (keypress)="numericOnly($event)" class="form-control" placeholder="*"
                    [ngClass]="{ 'is-invalid': isOTPsubmit && O.val4.errors }">

                </div>
            </div>

            <div style="text-align: center;padding: 10px;">
                <button type="button" (click)="verifyOTP()" class="btn btn-primary" [ngStyle]="{'background-color': getBackgroundColor()}">Verify OTP</button>
                <br>
                <br>
                <a (click)="resendOTP();" class="resend-otp" *ngIf="resendOtplLink">Resend OTP</a>
                <a *ngIf="">Expire Time: OTP will be expire after 3:00 minutes</a>
            </div>
        </form>

    </div>


    <!-- <ng-template pTemplate="footer">
        <button type="button" class="btn btn-primary">Verify OTP</button>
    </ng-template> -->
</p-dialog>
<div class="">
    <p-dialog header="Approved" [(visible)]="varifyApproved" [modal]="true" appendTo="body" styleClass="approve"
    [style]="{'width':'40vw'}" [baseZIndex]="10000" [draggable]="false" [resizable]="false" (onHide)="closeAll()">
    
    <div class="container">
        <ul class="list-group">
            <li class="list-group-item" style="color: red;">Your request has been forwarded to vendor once the vendor approve you will be able to login.</li>
          </ul>
    </div>
</p-dialog>
</div>




